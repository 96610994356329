/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx } from '@emotion/react'
import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiCheckableCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormFieldset,
  EuiText,
  EuiTitle,
  htmlIdGenerator,
  useGeneratedHtmlId,
} from '@elastic/eui'

import { useCardStyleOnHover } from '@modules/discovery-questions-components/styles'

import { StepLayout } from './StepLayout'

import type { FC } from 'react'

type OptionSelectorFormStepProps = {
  options: Array<{
    key: string
    label: JSX.Element
    description: JSX.Element
  }>
  onSubmit: () => void
  stepTitle: JSX.Element
  stepDescription: JSX.Element
  value: string | undefined
  onChange: (value: string) => void
  footer?: JSX.Element | null
}

const OptionSelectorFormStep: FC<OptionSelectorFormStepProps> = ({
  stepDescription,
  options,
  stepTitle,
  value,
  footer: customFooter,
  onSubmit,
  onChange,
}) => {
  const radioGroupId = useGeneratedHtmlId({ prefix: 'radioGroup' })

  const cardStyleOnHover = useCardStyleOnHover()

  const generateHtmlId = htmlIdGenerator()

  const footer =
    customFooter === undefined ? (
      <div>
        <EuiButton
          data-test-id='option-selector.submit'
          fill={true}
          type={'submit'}
          disabled={!value}
        >
          <FormattedMessage id='option-selector.continue' defaultMessage='Continue' />
        </EuiButton>
      </div>
    ) : (
      customFooter
    )

  return (
    <EuiForm
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
      component={'form'}
      name='option-selector-form'
    >
      <StepLayout title={stepTitle} description={stepDescription} footer={footer}>
        <EuiFormFieldset
          legend={{
            children: (
              <FormattedMessage
                id='option-selector.legend'
                defaultMessage='Choose the default solution view for the new deployment'
              />
            ),
            display: 'hidden',
          }}
        >
          <EuiFlexItem grow={false}>
            <EuiFlexGroup direction='column' gutterSize='m'>
              {options.map(({ key, label, description }) => (
                <div data-track-id={`option-selector.${key}`} key={key}>
                  <EuiCheckableCard
                    data-test-id='option-selector.use-case'
                    id={generateHtmlId()}
                    css={cardStyleOnHover}
                    label={
                      <Fragment>
                        <EuiTitle size='xs'>
                          <h2>{label}</h2>
                        </EuiTitle>
                        <EuiText size='s'>{description}</EuiText>
                      </Fragment>
                    }
                    name={radioGroupId}
                    value={key}
                    checked={value === key}
                    onClick={() => onChange(key)}
                    onChange={() => onChange(key)}
                  />
                </div>
              ))}
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFormFieldset>
      </StepLayout>
    </EuiForm>
  )
}

export default OptionSelectorFormStep
