/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

const RoleMappings = () => (
  <EuiPanel paddingSize='xl'>
    <EuiTitle size='m'>
      <h1>Role Mappings</h1>
    </EuiTitle>
    <EuiSpacer size='m' />
    <NoRoleMappings />
  </EuiPanel>
)

const NoRoleMappings = () => (
  <EuiFlexGroup className='no-data-flex-group'>
    <EuiFlexItem className='no-data-left-panel'>
      <Description />
    </EuiFlexItem>
    <EuiFlexItem className='no-data-right-panel'>
      <EuiText>
        <EuiEmptyPrompt
          className='no-data-prompt'
          color='subdued'
          title={
            <h4>
              <FormattedMessage
                id='organization.security.role-mappings.title-create-your-first-role-mapping'
                defaultMessage='Create your first role mapping'
              />
            </h4>
          }
          body={
            <p>
              <FormattedMessage
                id='organization.security.role-mappings.description'
                defaultMessage='Role mappings control which roles are assigned to your users.'
              />
            </p>
          }
          actions={
            <EuiButton color='primary' fill={true}>
              <FormattedMessage
                id='organization.security.role-mappings.btn-create-role-mappings'
                defaultMessage='Create role mapping'
              />
            </EuiButton>
          }
        />
      </EuiText>
    </EuiFlexItem>
  </EuiFlexGroup>
)

const Description = () => (
  <EuiText color='subdued'>
    <FormattedMessage
      id='organization.security.role-mappings.description-learn-more'
      defaultMessage='Define which roles are assigned to members of the organization added through your external identity provider.'
    />
  </EuiText>
)

export default RoleMappings
