/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { DeploymentRoleAssignment, ProjectRoleAssignment } from '@modules/cloud-api/v1/types'
import type { AnyProject } from '@modules/project-api/types'
import type { ProjectType } from '@modules/ui-types/projects'

import type { MessageDescriptor } from 'react-intl'

export const ROLE_IDS = {
  organization: ['organization-admin', 'billing-admin'] as const,
  deployment: ['deployment-admin', 'deployment-editor', 'deployment-viewer'] as const,
  elasticsearch: [
    'elasticsearch-admin',
    'elasticsearch-developer',
    'elasticsearch-viewer',
  ] as const,
  observability: ['observability-admin', 'observability-editor', 'observability-viewer'] as const,
  security: [
    'security-admin',
    'security-editor',
    'security-viewer',
    'security-t1-analyst',
    'security-t2-analyst',
    'security-t3-analyst',
    'security-threat-intel-analyst',
    'security-rule-author',
    'security-soc-manager',
    'security-endpoint-operations-analyst',
    'security-platform-engineer',
    'security-detections-admin',
    'security-endpoint-policy-manager',
  ] as const,
}

export type OrganizationRoleId = (typeof ROLE_IDS.organization)[number]
export type DeploymentRoleId = (typeof ROLE_IDS.deployment)[number]
export type ElasticsearchProjectRoleId = (typeof ROLE_IDS.elasticsearch)[number]
export type ObservabilityProjectRoleId = (typeof ROLE_IDS.observability)[number]
export type SecurityProjectRoleId = (typeof ROLE_IDS.security)[number]

export type ProjectRoleId =
  | ElasticsearchProjectRoleId
  | ObservabilityProjectRoleId
  | SecurityProjectRoleId

export type ResourceRoleId<T extends ResourceType> = T extends ProjectType
  ? ProjectRoleId
  : DeploymentRoleId

export type RoleId = OrganizationRoleId | ResourceRoleId<'deployment'> | ResourceRoleId<ProjectType>

export type OrganizationRoleLabel = OrganizationRoleId
export type DeploymentRoleLabel = 'admin' | 'editor' | 'viewer'
export type ElasticsearchRoleLabel = 'admin' | 'developer' | 'viewer'
export type ObservabilityRoleLabel = 'admin' | 'editor' | 'viewer'
export type SecurityRoleLabel =
  | 'admin'
  | 'editor'
  | 'viewer'
  | 'tier1Analyst'
  | 'tier2Analyst'
  | 'tier3Analyst'
  | 'threatIntelligenceAnalyst'
  | 'ruleAuthor'
  | 'socManager'
  | 'endpointOperationsAnalyst'
  | 'platformEngineer'
  | 'detectionsAdmin'
  | 'endpointPolicyManager'
export type CustomRoleBadgeLabelType = 'customRole'
export const CustomRoleBadgeLabel = 'customRole'

export type ProjectRoleLabel = ElasticsearchRoleLabel | ObservabilityRoleLabel | SecurityRoleLabel
export type ResourceRoleLabel = DeploymentRoleLabel | ProjectRoleLabel | CustomRoleBadgeLabelType
export type RoleLabel = OrganizationRoleLabel | ResourceRoleLabel | CustomRoleBadgeLabelType

export type TableItemRoleDescriptor = { roleId: string; isCustomRole?: boolean }

export type RoleAssignmentsTableItem<T extends ResourceType> = {
  resourceType: T
  id: string
  name: string
  roles: TableItemRoleDescriptor[]
  isDisabled: boolean
  roleManagementUrl?: string
}

export type ResourceType = 'deployment' | ProjectType

export type DeploymentResource = { id: string; name: string }

export type Resource<T extends ResourceType> = T extends ProjectType
  ? AnyProject
  : DeploymentResource

export type ResourceRoleAssignment<T extends ResourceType> = T extends ProjectType
  ? ProjectRoleAssignment
  : DeploymentRoleAssignment

export type NormalisedResourceRoleAssignment<T extends ResourceType = ResourceType> = Omit<
  ResourceRoleAssignment<T>,
  'role_id' | 'project_ids' | 'deployment_ids'
> & {
  role_id: ResourceRoleId<T>
  ids?: string[]
  resourceType?: ResourceType
}

export type ResouceRoleAssignmentsDiff = {
  added: {
    elasticsearch: Array<ResourceRoleAssignment<'elasticsearch'>>
    observability: Array<ResourceRoleAssignment<'observability'>>
    security: Array<ResourceRoleAssignment<'security'>>
  }
  removed: {
    elasticsearch: Array<ResourceRoleAssignment<'elasticsearch'>>
    observability: Array<ResourceRoleAssignment<'observability'>>
    security: Array<ResourceRoleAssignment<'security'>>
  }
}

export const deploymentRoleIdsByLabel: Record<DeploymentRoleLabel, DeploymentRoleId> = {
  admin: 'deployment-admin',
  editor: 'deployment-editor',
  viewer: 'deployment-viewer',
}

export const elasticsearchRoleIdsByLabel: Record<
  ElasticsearchRoleLabel,
  ElasticsearchProjectRoleId
> = {
  admin: 'elasticsearch-admin',
  developer: 'elasticsearch-developer',
  viewer: 'elasticsearch-viewer',
}

export const observabilityRoleIdsByLabel: Record<
  ObservabilityRoleLabel,
  ObservabilityProjectRoleId
> = {
  admin: 'observability-admin',
  editor: 'observability-editor',
  viewer: 'observability-viewer',
}

export const securityRoleIdsByLabel: Record<SecurityRoleLabel, SecurityProjectRoleId> = {
  admin: 'security-admin',
  editor: 'security-editor',
  viewer: 'security-viewer',
  tier1Analyst: 'security-t1-analyst',
  tier2Analyst: 'security-t2-analyst',
  tier3Analyst: 'security-t3-analyst',
  threatIntelligenceAnalyst: 'security-threat-intel-analyst',
  ruleAuthor: 'security-rule-author',
  socManager: 'security-soc-manager',
  endpointOperationsAnalyst: 'security-endpoint-operations-analyst',
  platformEngineer: 'security-platform-engineer',
  detectionsAdmin: 'security-detections-admin',
  endpointPolicyManager: 'security-endpoint-policy-manager',
}

// Note:i18n is performed at the component level based on the label here
export const resourceRoleLabelByRoleId: Record<
  ResourceRoleId<'deployment'> | ResourceRoleId<ProjectType>,
  ResourceRoleLabel
> = {
  'deployment-admin': 'admin',
  'deployment-editor': 'editor',
  'deployment-viewer': 'viewer',
  'elasticsearch-admin': 'admin',
  'elasticsearch-developer': 'developer',
  'elasticsearch-viewer': 'viewer',
  'observability-admin': 'admin',
  'observability-editor': 'editor',
  'observability-viewer': 'viewer',
  'security-admin': 'admin',
  'security-editor': 'editor',
  'security-viewer': 'viewer',
  'security-t1-analyst': 'tier1Analyst',
  'security-t2-analyst': 'tier2Analyst',
  'security-t3-analyst': 'tier3Analyst',
  'security-threat-intel-analyst': 'threatIntelligenceAnalyst',
  'security-rule-author': 'ruleAuthor',
  'security-soc-manager': 'socManager',
  'security-endpoint-operations-analyst': 'endpointOperationsAnalyst',
  'security-platform-engineer': 'platformEngineer',
  'security-detections-admin': 'detectionsAdmin',
  'security-endpoint-policy-manager': 'endpointPolicyManager',
}

export const resourceRoleIdsOrdering: Record<
  ResourceRoleId<'deployment'> | ResourceRoleId<ProjectType>,
  number
> = {
  'deployment-admin': 1,
  'deployment-editor': 2,
  'deployment-viewer': 3,
  'elasticsearch-admin': 1,
  'elasticsearch-developer': 2,
  'elasticsearch-viewer': 3,
  'observability-admin': 1,
  'observability-editor': 2,
  'observability-viewer': 3,
  'security-admin': 1,
  'security-editor': 2,
  'security-viewer': 3,
  'security-t1-analyst': 4,
  'security-t2-analyst': 5,
  'security-t3-analyst': 6,
  'security-threat-intel-analyst': 7,
  'security-rule-author': 8,
  'security-soc-manager': 9,
  'security-endpoint-operations-analyst': 10,
  'security-platform-engineer': 11,
  'security-detections-admin': 12,
  'security-endpoint-policy-manager': 13,
}

export type RoleScopeType =
  | 'platform'
  | 'organization'
  | 'deployment'
  | 'project-elasticsearch'
  | 'project-observability'
  | 'project-security'

export type RoleBadgeIcon =
  | 'logoElasticsearch'
  | 'logoObservability'
  | 'logoSecurity'
  | 'logoElasticStack'

export type RoleOptionDefinition = {
  roleId: string
  labelDescriptor: MessageDescriptor
  descriptionDescriptor: MessageDescriptor
  isCustomRole?: boolean
}
