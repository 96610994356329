/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment, useState } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

import {
  EuiButton,
  EuiFlexGroup,
  EuiSelect,
  EuiSkeletonText,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui'

import { useGetProjectMetricsQuery } from '@modules/autoops-lib/hooks'
import { type DateRangeOption, DEFAULT_RANGE_OPTION } from '@modules/autoops-lib/constants'
import type { ProjectType } from '@modules/ui-types/projects'
import ElasticsearchCharts from '@modules/auto-ops-components/MetricsChart/ElasticsearchCharts'
import DefaultCharts from '@modules/auto-ops-components/MetricsChart/DefaultCharts'

type AutoOpsMetricsChartProps = {
  projectId: string
  projectType: ProjectType
  organizationId: string
}

const messages = defineMessages({
  last3Hours: {
    id: `project.time_range_options.last_3_hours`,
    defaultMessage: `Last 3 hours`,
  },
  last6Hours: {
    id: `project.time_range_options.last_6_hours`,
    defaultMessage: `Last 6 hours`,
  },
  last12Hours: {
    id: `project.time_range_options.last_12_hours`,
    defaultMessage: `Last 12 hours`,
  },
  last24Hours: {
    id: `project.time_range_options.last_24_hours`,
    defaultMessage: `Last 24 hours`,
  },
  last2Days: {
    id: `project.time_range_options.last_2_days`,
    defaultMessage: `Last 2 days`,
  },
  last7Days: {
    id: `project.time_range_options.last_7_days`,
    defaultMessage: `Last 7 days`,
  },
})

const AutoOpsMetricsChart = ({
  projectId,
  projectType,
  organizationId,
}: AutoOpsMetricsChartProps) => {
  const isElasticsearch = projectType === 'elasticsearch'
  const [from, setFrom] = useState<string>(DEFAULT_RANGE_OPTION)
  const query = useGetProjectMetricsQuery({
    projectId,
    projectType,
    organizationId,
    range: { from, to: 'now' },
  })
  const { formatMessage } = useIntl()
  const timeRangeSelectorOptions: Array<{ value: DateRangeOption; text: string }> = [
    { value: 'now-3h', text: formatMessage(messages.last3Hours) },
    { value: 'now-6h', text: formatMessage(messages.last6Hours) },
    { value: 'now-12h', text: formatMessage(messages.last12Hours) },
    { value: 'now-24h', text: formatMessage(messages.last24Hours) },
    { value: 'now-2d', text: formatMessage(messages.last2Days) },
    { value: 'now-7d', text: formatMessage(messages.last7Days) },
  ]

  if (query.isError) {
    return null
  }

  if (query.isLoading) {
    return (
      <Fragment>
        <EuiSkeletonText />
        <EuiSpacer size='xxl' />
      </Fragment>
    )
  }

  if (!query.isSuccess) {
    return null
  }

  return (
    <Fragment>
      <EuiFlexGroup gutterSize='l'>
        <EuiTitle size='s'>
          <h2>
            <FormattedMessage
              id='project.heading.usage_and_performance'
              defaultMessage='Usage and performance'
            />
          </h2>
        </EuiTitle>
        <EuiFlexGroup gutterSize='s' responsive={false} justifyContent='flexEnd'>
          <div>
            <EuiSelect
              options={timeRangeSelectorOptions}
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              aria-label='Time range selector'
            />
          </div>
          <EuiButton
            isLoading={query.isRefetching}
            color='primary'
            fill={true}
            iconType='refresh'
            onClick={() => query.refetch()}
          >
            {query.isRefetching ? 'Updating' : 'Refresh'}
          </EuiButton>
        </EuiFlexGroup>
      </EuiFlexGroup>

      <EuiSpacer size='l' />

      {isElasticsearch && <ElasticsearchCharts data={query.data} />}
      {!isElasticsearch && <DefaultCharts data={query.data} />}

      <EuiSpacer size='xxl' />
    </Fragment>
  )
}

export default AutoOpsMetricsChart
