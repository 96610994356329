/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { map, size } from 'lodash'
import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import {
  EuiButtonIcon,
  EuiFormControlLayout,
  EuiFormLabel,
  EuiSelect,
  EuiSpacer,
} from '@elastic/eui'

import type { DeploymentTemplateInfoV2, RepositoryConfig } from '@modules/cloud-api/v1/types'
import CuiToggleablePopoverForClassComp from '@modules/cui/CuiToggleablePopoverForClassComp'
import type { RegionId } from '@modules/ui-types'

import { getEsSettingsFromTemplate } from '@/lib/stackDeployments/selectors/deploymentTemplates'

import { createSnapshotRepositoryUrl } from '../../../lib/urlBuilder'

interface Props {
  deploymentTemplate?: DeploymentTemplateInfoV2 | null
  snapshotRepositoryId: string | undefined
  snapshotRepositories: { [repositoryName: string]: RepositoryConfig }
  regionId: RegionId
  setSnapshotRepositoryId: (snapshotRepositoryId: string | null) => void
}

class SnapshotRepository extends Component<Props> {
  componentDidMount(): void {
    this.disableSnapshot()
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { deploymentTemplate } = this.props
    // users can go and select a different template, which updates this component, and we need to add the default snapshot repository if it exists
    // in this new template.
    const previousTemplateDefaultRepoId = this.getDefaultRepository(prevProps.deploymentTemplate)
    const defaultSnapshotRepositoryId = this.getDefaultRepository(deploymentTemplate)

    if (previousTemplateDefaultRepoId !== defaultSnapshotRepositoryId) {
      this.setSnapshotRepository(defaultSnapshotRepositoryId || ``)
    }
  }

  render(): JSX.Element {
    const { deploymentTemplate, snapshotRepositoryId } = this.props
    const { snapshotRepositories, regionId } = this.props

    const defaultSnapshotRepositoryId = this.getDefaultRepository(deploymentTemplate)
    const hasDefaultRepository = Boolean(defaultSnapshotRepositoryId)
    const disabled = hasDefaultRepository || size(snapshotRepositories) === 0

    return (
      <Fragment>
        <EuiFormControlLayout
          fullWidth={true}
          prepend={
            <Fragment>
              <EuiFormLabel style={{ width: `180px` }}>
                <div>
                  <FormattedMessage
                    defaultMessage='Snapshot repository {icon}'
                    id='select-snapshot-repo-label'
                    values={{
                      icon: (
                        <CuiToggleablePopoverForClassComp
                          data-test-id='create-snapshot-repo-popover'
                          anchorPosition='upCenter'
                          ownFocus={true}
                          toggleButton={(togglePopoverFunc) => (
                            <EuiButtonIcon
                              aria-label='add-snapshot-repo'
                              onClick={togglePopoverFunc}
                              iconType='iInCircle'
                            />
                          )}
                        >
                          <FormattedMessage
                            defaultMessage='Back up your data using snapshots. {manageRepo}'
                            id='select-snapshot-repo-info-description'
                            values={{
                              manageRepo: (
                                <Link
                                  data-test-id='createDeployment-snapshotRepoSelect'
                                  to={createSnapshotRepositoryUrl(regionId)}
                                >
                                  <FormattedMessage
                                    id='deployment-configure.no-snapshot-repos-link'
                                    defaultMessage='Manage snapshot repositories'
                                  />
                                </Link>
                              ),
                            }}
                          />
                        </CuiToggleablePopoverForClassComp>
                      ),
                    }}
                  />
                </div>
              </EuiFormLabel>
            </Fragment>
          }
        >
          <EuiSelect
            disabled={disabled}
            fullWidth={true}
            data-test-id='createDeployment-snapshotRepoSelect'
            value={defaultSnapshotRepositoryId || snapshotRepositoryId || ``} // default takes precedence because if it exists, the select box is disabled
            onChange={(e) => this.setSnapshotRepository(e.target.value)}
            options={this.getSnapshotOptions(hasDefaultRepository, snapshotRepositoryId)}
          />

          <EuiSpacer size='m' />
        </EuiFormControlLayout>
      </Fragment>
    )
  }

  getSnapshotOptions(hasDefaultRepository, snapshotRepositoryId) {
    const { snapshotRepositories } = this.props
    const snapshotRepositoriesCount = size(snapshotRepositories)

    if (hasDefaultRepository && snapshotRepositoriesCount === 0) {
      return [{ value: snapshotRepositoryId, text: snapshotRepositoryId }]
    }

    if (snapshotRepositoriesCount > 0) {
      return [
        { value: ``, text: `` },
        ...map(snapshotRepositories, (repo) => ({
          value: repo.repository_name,
          text: `${repo.repository_name} (${repo.config.type})`,
        })),
      ]
    }

    return []
  }

  toggleSnapshots = (): void => {
    this.disableSnapshot()
  }

  disableSnapshot(): void {
    this.props.setSnapshotRepositoryId(null)
  }

  setSnapshotRepository(repoName: string): void {
    const { setSnapshotRepositoryId } = this.props

    if (repoName === ``) {
      return this.disableSnapshot()
    }

    setSnapshotRepositoryId(repoName)
  }

  getDefaultRepository(deploymentTemplate) {
    const settings = getEsSettingsFromTemplate({
      deploymentTemplate: deploymentTemplate?.deployment_template,
    })
    return settings?.snapshot?.repository?.reference?.repository_name
  }
}

export default SnapshotRepository
