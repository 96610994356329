/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { FormattedMessage } from 'react-intl'
import React from 'react'

import OptionSelectorFormStep from '@modules/cui/Step/OptionSelectorFormStep'

import type { FC } from 'react'
import type { DefaultSolutionView } from './types'

type DefaultSolutionViewSelectorProps = {
  solutionView?: DefaultSolutionView | undefined
  onChangeDefaultSolutionView: (value: DefaultSolutionView) => void
  onDefaultSolutionViewConfirmed: () => void
  footer?: JSX.Element | null
}

type DefaultSolutionOption = {
  key: DefaultSolutionView
  label: JSX.Element
  description: JSX.Element
}

const options: DefaultSolutionOption[] = [
  {
    key: 'search',
    label: <FormattedMessage id='default-solution-view.radio1' defaultMessage='Search' />,
    description: (
      <FormattedMessage
        id='default-solution-view.radio1-sub'
        defaultMessage='Develop your own search use cases with Elasticsearch APIs, storage, search, and analytics capabilities.'
      />
    ),
  },
  {
    key: 'observability',
    label: <FormattedMessage id='default-solution-view.radio2' defaultMessage='Observability' />,
    description: (
      <FormattedMessage
        id='default-solution-view.radio2-sub'
        defaultMessage='Collect and analyze your logs, APM data, and traces.'
      />
    ),
  },
  {
    key: 'security',
    label: <FormattedMessage id='default-solution-view.radio3' defaultMessage='Security' />,
    description: (
      <FormattedMessage
        id='default-solution-view.radio3-sub'
        defaultMessage='Detect and respond to threats by unifying your SIEM, endpoint, and cloud asset security data.'
      />
    ),
  },
]

const DefaultSolutionViewSelector: FC<DefaultSolutionViewSelectorProps> = ({
  solutionView,
  onChangeDefaultSolutionView,
  onDefaultSolutionViewConfirmed,
  footer,
}) => (
  <OptionSelectorFormStep
    stepTitle={
      <FormattedMessage
        id='default-solution-view.title'
        defaultMessage='Set the default solution view'
      />
    }
    stepDescription={
      <FormattedMessage
        id='default-solution-view.subtitle'
        defaultMessage='In Elastic 8.16 we upgraded the navigation for a more focused workflow. 
      To get started, set the default solution view for new spaces. 
      You can change this any time later. Learn more'
      />
    }
    options={options}
    value={solutionView}
    onChange={onChangeDefaultSolutionView}
    onSubmit={onDefaultSolutionViewConfirmed}
    footer={footer}
  />
)

export default DefaultSolutionViewSelector
