/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { css } from '@emotion/react'
import React, { Component } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { parse as parseQuery, stringify } from 'query-string'

import { EuiSpacer } from '@elastic/eui'
import { withTransaction } from '@elastic/apm-rum-react'

import LandingPageOuterContainer from '@modules/access-management-components/LandingPageOuterContainer'
import LandingPageInnerContainer from '@modules/access-management-components/LandingPageInnerContainer'
import type { AsyncRequestError } from '@modules/ui-types'
import { AjaxRequestError } from '@modules/ui-types'
import { asAjaxRequestError } from '@modules/utils/ajax'
import AuthContext from '@modules/auth/context'

import AppLoadingRoot from '@/components/AppLoadingRoot'

import { getConfigForKey } from '../../store'
import SpinButton from '../SpinButton'
import TermsOfService from '../CreateAccountForm/TermsOfService'
import NotFound from '../ErrorPages/NotFound'

import { SsoError } from './SsoError'

import type { AllProps as Props } from './types'
import type { WrappedComponentProps } from 'react-intl'
import type { ReactElement } from 'react'

const messages = defineMessages({
  login: {
    id: `cloud-sso-page-default-title`,
    defaultMessage: `Log in to your account via SSO`,
  },
  loginButton: {
    id: `cloud-sso-page.login-button`,
    defaultMessage: `Log in via SSO`,
  },
})

class UserconsoleSso extends Component<Props & WrappedComponentProps> {
  context!: React.ContextType<typeof AuthContext>

  componentDidMount() {
    this.onLoadInitiateUrl()
    this.onLoadExchangeIdToken()
  }

  componentDidUpdate(prevProps: Props) {
    const { loginIdentifier: prevLoginIdentifier, idToken: prevIdToken } = prevProps
    const { loginIdentifier, idToken } = this.props

    if (prevLoginIdentifier !== loginIdentifier || prevIdToken !== idToken) {
      this.resetRequests()
    }
  }

  render() {
    const {
      exchangeSsoRequest,
      history: {
        location: { state: locationState },
      },
      idToken,
      oktaError,
      initiateSsoRequest,
      loginIdentifier,
    } = this.props
    const { error: initiateSsoError, inProgress: initiateSsoInProgress } = initiateSsoRequest
    const {
      inProgress: exchangeSsoInProgress,
      isDone: exchangeSsoDone,
      error: exchangeSsoError,
    } = exchangeSsoRequest

    if (initiateSsoError && this.isNonRedirectError(initiateSsoError)) {
      const errorCodes = asAjaxRequestError(initiateSsoError)?.body?.errors?.map((err) => err.code)

      if (errorCodes && errorCodes.length > 0 && errorCodes[0] === 'auth.no_org_for_login_id') {
        return <NotFound data-test-id='sso-org-not-found' withHttpErrorPage={false} />
      }
    }

    if (!loginIdentifier && !idToken) {
      // TODO implement lookup by email https://elasticco.atlassian.net/browse/CP-6475
      return <NotFound data-test-id='sso-org-not-found' withHttpErrorPage={false} />
    }

    if (
      initiateSsoInProgress ||
      (!oktaError &&
        (exchangeSsoInProgress ||
          (exchangeSsoDone && !exchangeSsoError) ||
          (!exchangeSsoDone && !!idToken && !exchangeSsoError)))
    ) {
      return <AppLoadingRoot />
    }

    return (
      <LandingPageOuterContainer
        pageContext={{
          name: 'login',
          contextSwitchLocationState: locationState,
        }}
        isFlowV2={true}
      >
        <LandingPageInnerContainer
          title={this.renderFormTitle()}
          panelProps={{
            'aria-live': 'polite',
          }}
          isFlowV2={true}
          panelCssOverride={css({ maxWidth: '430px' })}
        >
          {this.renderBody()}
        </LandingPageInnerContainer>
      </LandingPageOuterContainer>
    )
  }

  renderBody(): ReactElement {
    const {
      exchangeSsoRequest: { error: exchangeSsoError },
      initiateSsoRequest: { error: initiateSsoError },
      redirectTo,
      state,
      oktaError,
    } = this.props

    const error: AsyncRequestError | undefined =
      oktaError ??
      (initiateSsoError && this.isNonRedirectError(initiateSsoError)
        ? initiateSsoError
        : exchangeSsoError)

    if (error) {
      return (
        <React.Fragment>
          <EuiSpacer size='m' />
          <SsoError error={error} redirectTo={redirectTo} state={state} />
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <TermsOfService />
        <EuiSpacer size='m' />
        <SpinButton
          type='submit'
          data-test-id='sso-login-button'
          color='primary'
          fill={true}
          buttonProps={{ fullWidth: true }}
          onClick={() => {
            this.onClickInitiateUrl()
          }}
        >
          <FormattedMessage {...messages.loginButton} />
        </SpinButton>
      </React.Fragment>
    )
  }

  renderFormTitle(): ReactElement {
    return <FormattedMessage data-test-id={messages.login.id} {...messages.login} />
  }

  static contextType = AuthContext

  isNonRedirectError(error) {
    if (error instanceof AjaxRequestError) {
      return error.response.type !== `opaqueredirect`
    }

    return true
  }

  isEssUserconsole() {
    return (
      getConfigForKey(`APP_PLATFORM`) === `saas` && getConfigForKey(`APP_NAME`) === `userconsole`
    )
  }

  // This checks up-front if initiation will redirect. Detects 404s and other errors that may prevent
  // successful SSO-initiation
  onLoadInitiateUrl() {
    const { loginIdentifier, initiateSsoCheck, redirectTo } = this.props

    if (loginIdentifier) {
      initiateSsoCheck({
        redirectUri: `${location.protocol}//${location.host}/login/sso`,
        state: stringify({ redirectTo, hasAcceptedTermsAndPolicies: true, loginIdentifier }),
        loginIdentifier,
      })
    }
  }

  onClickInitiateUrl() {
    const { loginIdentifier, initiateSsoRedirect, redirectTo } = this.props

    if (loginIdentifier) {
      this.context.setAuthContext({ method: 'byoidp' })
      initiateSsoRedirect({
        redirectUri: `${location.protocol}//${location.host}/login/sso`,
        state: stringify({ redirectTo, hasAcceptedTermsAndPolicies: true, loginIdentifier }),
        loginIdentifier,
      })
    }
  }

  // Exchanges the id token in the hash for a Cloud session
  onLoadExchangeIdToken() {
    const { idToken, exchangeIdToken, state } = this.props

    const parsedState = parseQuery(String(state))
    const redirectTo = typeof parsedState.redirectTo === `string` ? parsedState.redirectTo : '/'
    const hasAcceptedTermsAndPolicies = parsedState.hasAcceptedTermsAndPolicies === 'true' || false

    if (idToken) {
      exchangeIdToken(idToken, redirectTo, hasAcceptedTermsAndPolicies)
    }
  }

  resetRequests() {
    const { resetInitiateSsoCheck, resetExchangeIdToken } = this.props

    resetInitiateSsoCheck()
    resetExchangeIdToken()
  }
}

export default withTransaction(`UserconsoleSso`, `component`)(injectIntl(UserconsoleSso))
