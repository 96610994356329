/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

const UserAuthentication = () => (
  <EuiPanel paddingSize='xl'>
    <EuiFlexGroup>
      <EuiFlexItem>
        <EuiTitle size='m'>
          <h1>User Authentication</h1>
        </EuiTitle>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButton size='m'>
          <FormattedMessage id='user-authentication.edit' defaultMessage='Edit' />
        </EuiButton>
      </EuiFlexItem>
    </EuiFlexGroup>
    <EuiSpacer size='l' />
    <EuiFlexGroup className='no-data-flex-group'>
      <EuiFlexItem className='no-data-left-panel'>
        <EuiTitle size='xs'>
          <h3>
            <FormattedMessage
              id='user-authentication.login-identifier'
              defaultMessage='Login identifier'
            />
          </h3>
        </EuiTitle>
      </EuiFlexItem>
      <EuiFlexItem className='no-data-right-panel'>
        <EuiText>
          <FormattedMessage
            id='user-authentication.normal-login'
            defaultMessage='Placeholder for login information'
          />
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  </EuiPanel>
)

export default UserAuthentication
