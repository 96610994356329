/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/* global FS */
/** @jsx jsx */
import { parse } from 'querystring'

import { Component, Fragment, createRef } from 'react'
import cx from 'classnames'
import { css, jsx } from '@emotion/react'
// eslint-disable-next-line no-restricted-imports
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { noop } from 'lodash'
import { sha256 } from 'js-sha256'

import { EuiErrorBoundary, EuiPage, EuiPageBody, EuiPageSection, useEuiTheme } from '@elastic/eui'

import { themedBoxShadow } from '@modules/cui/styles/boxShadow'
import history from '@modules/utils/history'
import { BreadcrumbsContext } from '@modules/cui/Breadcrumbs'
import ProfileProvider from '@modules/profile-lib/ProfileProvider'
import LocalStorageKey from '@modules/utils/localStorageKeys'
import { makeLaunchDarklyContext } from '@modules/launchdarkly'
import BillingDetailsProvider from '@modules/billing-lib/billingDetails/BillingDetailsProvider'
import PermissionsGate from '@modules/permissions-components/PermissionsGate'
import TrialSummary from '@modules/trial-components/TrialSummary'
import DiscoveryQuestions from '@modules/discovery-questions-pages/DiscoveryQuestionsV0/DiscoveryQuestions'
import { isTrialUser } from '@modules/billing-lib/utils'
import applyMiddleware from '@modules/app/applyMiddleware'
import MfaEnforcementMiddleware from '@modules/mfa-enforcement/MfaEnforcementMiddleware'

import { setCloudTrialCookie } from '@/lib/cookies'
import { AppRouterContext } from '@/components/AppRouter'
import { isMarketplacePseudoTrial, isMarketplaceTrial } from '@/lib/marketPlace'
import RefreshApiToken from '@/components/RefreshApiToken'
import SchedulerMiddleware from '@/lib/schedule/SchedulerMiddleware'

import UserconsoleChromeNavigation from '../UserconsoleChromeNavigation'
import NotificationBanner from '../NotificationBanner'
import AppLoadingRoot from '../../../../components/AppLoadingRoot'
import ChromeHeader from '../../../../components/ChromeHeader'
import PhoneHomeData from '../../../../components/PhoneHome/Data'
import { isElasticStaff as isElasticEmail } from '../../../../lib/validateEmail'

import ProfileSchedule from './ProfileSchedule'

import type { FC } from 'react'
import type { Props } from './types'

interface State {
  initializedFullStory: boolean
  initializedLaunchDarkly: boolean
  initializeLaunchDarklyFailed: boolean
  isTrialModalDismissed: boolean
}

type PropsWithTheme = Props

class App extends Component<PropsWithTheme, State> {
  state: State = {
    initializedFullStory: false,
    initializedLaunchDarkly: false,
    initializeLaunchDarklyFailed: false,
    isTrialModalDismissed:
      localStorage.getItem(LocalStorageKey.trialExperienceDismissed) === 'true',
  }

  breadcrumbsRef = createRef<HTMLDivElement>()

  announcementsBreadcrumbsRef = createRef<HTMLDivElement>()

  componentDidMount() {
    const { fetchProfileIfNeeded, fetchEolStatusIfNeeded, fetchAuthzRoles, profile } = this.props

    fetchEolStatusIfNeeded()
    fetchAuthzRoles()

    if (!profile) {
      fetchProfileIfNeeded({ force: true })
        .then(() => {
          this.initializeApp()
        })
        .catch(noop)
      return
    }

    this.initializeApp()
  }

  componentDidUpdate() {
    const { profile } = this.props

    if (profile && profile.inTrial) {
      setCloudTrialCookie(profile)
    }

    this.initializeLaunchDarkly()
  }

  render() {
    const { profile, fetchProfileIfNeeded, fetchProfileRequest, shouldDisplayGlobalHeader } =
      this.props

    const { isTrialModalDismissed } = this.state
    const pending_level = profile?.pending_level

    return applyMiddleware(
      [ProfileProvider, MfaEnforcementMiddleware, SchedulerMiddleware],
      <BillingDetailsProvider organization_id={profile?.organization_id}>
        {profile && profile.hasExpiredTrial && !isTrialModalDismissed && (
          <TrialSummary onClose={this.onDismissTrialModal} />
        )}

        {shouldDisplayGlobalHeader && this.renderHeaderAndNotifications()}

        {this.renderContent()}

        {pending_level && (
          <ProfileSchedule
            fetchProfile={fetchProfileIfNeeded}
            busy={fetchProfileRequest.inProgress}
          />
        )}

        <RefreshApiToken />
      </BillingDetailsProvider>,
    )
  }

  renderHeaderAndNotifications() {
    const { hideNavigation, profile } = this.props

    if (!this.isAppInitialized() || !profile) {
      return null
    }

    if (this.showDiscoveryQuestions() || this.isServerlessDiscoveryQuestions()) {
      return null
    }

    const inTrial = profile.inTrial

    return (
      <Fragment>
        <ChromeHeader
          showBreadcrumbs={!inTrial || !hideNavigation}
          showDrift={true}
          showHelp={true}
          breadcrumbsRef={this.breadcrumbsRef}
          announcementsRef={this.announcementsBreadcrumbsRef}
        />

        <PermissionsGate
          permissions={[
            {
              type: 'feature-usage',
              action: 'list',
            },
          ]}
        >
          {({ hasPermissions }) => <NotificationBanner hasPermissions={hasPermissions} />}
        </PermissionsGate>
      </Fragment>
    )
  }

  renderContent() {
    const { profile, isPortalRoute, children, hideNavigation, isRouteFSTraced } = this.props
    const showDiscoveryQuestions = this.showDiscoveryQuestions()
    const isHideNavigation = hideNavigation || showDiscoveryQuestions

    if (profile == null || !this.isAppInitialized()) {
      return <AppLoadingRoot />
    }

    const pageContents = (
      <Fragment>
        <PhoneHomeData />
        <EuiErrorBoundary>
          <BreadcrumbsContext.Provider
            value={{
              breadcrumbsRef: this.breadcrumbsRef,
              announcementsRef: this.announcementsBreadcrumbsRef,
            }}
          >
            {showDiscoveryQuestions ? (
              <DiscoveryQuestions userId={profile.user_id}>{children}</DiscoveryQuestions>
            ) : (
              children
            )}
          </BreadcrumbsContext.Provider>
        </EuiErrorBoundary>
      </Fragment>
    )

    // portal renders its own `<EuiPage>` frame
    if (isPortalRoute || showDiscoveryQuestions) {
      return pageContents
    }

    return (
      <CloudAppPage
        isHideNavigation={isHideNavigation}
        isRouteFSTraced={isRouteFSTraced}
        pageContents={pageContents}
      />
    )
  }

  isPollingEnabled() {
    return this.props.pollingInterval > 0
  }

  onDismissTrialModal = () => {
    localStorage.setItem(LocalStorageKey.trialExperienceDismissed, 'true')
    this.setState({ isTrialModalDismissed: true })
  }

  initializeApp() {
    const { profile } = this.props

    if (!profile || !profile.user_id) {
      return
    }

    const isElasticStaff = isElasticEmail(profile.email)
    const id = profile.user_id.toString()

    this.initializeFullStory({ id, isElasticStaff })
    this.initializeLaunchDarkly()
  }

  initializeFullStory({ id, isElasticStaff }) {
    const { profile, isFullStoryActivated } = this.props
    const { initializedFullStory } = this.state
    const hashedId = sha256(id)

    if (!profile) {
      return
    }

    if (isFullStoryActivated && !initializedFullStory) {
      FS.identify(hashedId)
      FS.setUserVars({
        trial: profile.inTrial,
        hasExpiredTrial: profile.hasExpiredTrial,
        level: profile.level,
        ...(profile.organization_id ? { organization: profile.organization_id } : {}),
        isElasticStaff,
        rawUserId: id,
      })
      this.setState({ initializedFullStory: true })
    }
  }

  initializeLaunchDarkly = async () => {
    const { profile, ldClient, isLaunchDarklyActivated } = this.props
    const { initializedLaunchDarkly } = this.state

    if (!profile) {
      return
    }

    if (!ldClient || initializedLaunchDarkly || !isLaunchDarklyActivated) {
      return
    }

    try {
      await ldClient.waitForInitialization()
      await ldClient.identify(makeLaunchDarklyContext(profile), undefined, () =>
        this.setState({ initializedLaunchDarkly: true }),
      )
    } catch (error) {
      this.setState({ initializeLaunchDarklyFailed: true })
    }
  }

  showDiscoveryQuestions() {
    const { profile, isMarketplaceConversion } = this.props

    if (!this.isAppInitialized() || !profile) {
      return false
    }

    const {
      data: { registration_source },
    } = profile

    if (
      profile.hasExpiredTrial ||
      isMarketplaceConversion ||
      this.isOrganizationInvitation(registration_source) ||
      this.isAwsMarketplaceRegistration() ||
      this.isServerlessDiscoveryQuestions() ||
      registration_source === 'community' ||
      registration_source === 'training' ||
      registration_source === 'support'
    ) {
      return false
    }

    return !profile.data.discovery && (isTrialUser(profile) || this.isMarketplaceUser())
  }

  isAppInitialized() {
    const { isLaunchDarklyActivated } = this.props
    const { initializedLaunchDarkly, initializeLaunchDarklyFailed } = this.state

    if (!isLaunchDarklyActivated || initializeLaunchDarklyFailed) {
      return true
    }

    return initializedLaunchDarkly
  }

  isOrganizationInvitation(registration_source) {
    const { isOrganizationInvitation } = this.props
    return registration_source === 'organization-invite' || isOrganizationInvitation
  }

  isAwsMarketplaceRegistration() {
    return history.location.pathname === '/marketplace/registration'
  }

  isServerlessDiscoveryQuestions() {
    const { search } = history.location
    const query = parse(search.slice(1))

    return history.location.pathname.startsWith('/onboarding') || query.onboarding_token
  }

  isMarketplaceUser() {
    const { profile } = this.props

    if (!profile) {
      return false
    }

    return isMarketplaceTrial(profile) || isMarketplacePseudoTrial(profile)
  }
}

const CloudAppPage: FC<{
  isHideNavigation: boolean
  isRouteFSTraced: boolean | undefined
  pageContents: JSX.Element
}> = ({ isHideNavigation, isRouteFSTraced, pageContents }) => {
  const theme = useEuiTheme()

  const cloudContentStyle = css`
    background: ${!isHideNavigation
      ? theme.euiTheme.colors.emptyShade
      : theme.euiTheme.colors.body};
    box-shadow: ${themedBoxShadow({ theme })};
  `

  return (
    <EuiPage className={cx({ 'fs-unmask': isRouteFSTraced })}>
      {!isHideNavigation && (
        <aside className='cloudSidebar'>
          <AppRouterContext.Consumer>
            {({ routes }) => <UserconsoleChromeNavigation routes={routes} />}
          </AppRouterContext.Consumer>
        </aside>
      )}

      <div
        className={cx('cloudContent', { createPage: isHideNavigation })}
        id='cloudPortalPage'
        css={cloudContentStyle}
      >
        <EuiPageBody>
          <EuiPageSection className='cloudContentBody' paddingSize='m' color='transparent'>
            <div data-app='appContentBody'>{pageContents}</div>
          </EuiPageSection>
        </EuiPageBody>
      </div>
    </EuiPage>
  )
}

export default withLDConsumer()(App)
