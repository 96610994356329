/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSplitPanel,
  withEuiTheme,
  type WithEuiThemeProps,
} from '@elastic/eui'
import {
  AreaSeries,
  Axis,
  Chart,
  DARK_THEME,
  LIGHT_THEME,
  LineSeries,
  Position,
  ScaleType,
  Settings,
  timeFormatter,
} from '@elastic/charts'

import type { GetProjectMetricsResponse } from '@modules/autoops-api/types'

type DefaultChartsProps = WithEuiThemeProps & { data: GetProjectMetricsResponse }

const baseTheme = {
  LIGHT: LIGHT_THEME,
  DARK: DARK_THEME,
}

const DefaultCharts = ({ data, theme }: DefaultChartsProps) => {
  const [ingest, storage] = data.metricSeries

  return (
    <EuiFlexGroup gutterSize='l'>
      <EuiFlexItem>
        <EuiSplitPanel.Outer grow={true} hasBorder={true}>
          <EuiSplitPanel.Inner>
            <Chart title='Ingest Rate' size={{ height: '200px' }}>
              <Settings baseTheme={baseTheme[theme.colorMode]} />

              <Axis
                id='bottom'
                position={Position.Bottom}
                tickFormat={timeFormatter('DD-MM hh:mm a')}
              />

              <Axis
                id='left'
                position={Position.Left}
                tickFormat={(d) => `${Number(d).toFixed(2)}`}
              />

              {ingest?.metrics.map((metricItem) => (
                <LineSeries
                  key={metricItem.name}
                  id={metricItem.name}
                  xScaleType={ScaleType.Time}
                  yScaleType={ScaleType.Linear}
                  xAccessor={0}
                  yAccessors={[1]}
                  data={metricItem.data}
                />
              ))}
            </Chart>
          </EuiSplitPanel.Inner>
        </EuiSplitPanel.Outer>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiSplitPanel.Outer grow={true} hasBorder={true}>
          <EuiSplitPanel.Inner>
            <Chart title='Storage retained' size={{ height: '200px' }}>
              <Settings baseTheme={baseTheme[theme.colorMode]} />

              <Axis
                id='bottom'
                position={Position.Bottom}
                tickFormat={timeFormatter('DD-MM hh:mm a')}
              />

              <Axis
                id='left'
                position={Position.Left}
                tickFormat={(d) => `${Number(d).toFixed(2)}`}
              />

              {storage?.metrics.map((metricItem) => (
                <AreaSeries
                  key={metricItem.name}
                  id={metricItem.name}
                  xScaleType={ScaleType.Time}
                  yScaleType={ScaleType.Linear}
                  xAccessor={0}
                  yAccessors={[1]}
                  data={metricItem.data}
                />
              ))}
            </Chart>
          </EuiSplitPanel.Inner>
        </EuiSplitPanel.Outer>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default withEuiTheme(DefaultCharts)
