/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'

import { EuiSplitPanel, useEuiTheme } from '@elastic/eui'

import type { DeploymentTemplateInfoV2, ClusterCredentials } from '@modules/cloud-api/v1/types'
import type {
  StackDeployment,
  AsyncRequestState,
  GettingStartedType,
  SliderInstanceType,
  UserProfile,
} from '@modules/ui-types'
import { getUserUsecase } from '@modules/profile-lib'
import { useFlagsWhenLoaded } from '@modules/launchdarkly'

import { getLinks } from '@/lib/deployments/links'
import { kibanaGettingStartedUrl } from '@/lib/serviceProviderDeepLinks'
import { isHidden } from '@/lib/stackDeployments/selectors/metadata'
import { getFirstSliderClusterFromGet } from '@/lib/stackDeployments/selectors/fundamentals'
import {
  isAnyResourceChanging,
  isEveryResourceStarted,
  isEsStopping,
  isEsStopped,
} from '@/lib/stackDeployments/selectors/configurationChanges'
import {
  hasHealthyResourcePlan,
  hasFailedCreatePlan,
} from '@/lib/stackDeployments/selectors/configurationChangeHealth'

import DeploymentGettingStartedHeader from './DeploymentGettingStartedHeader'
import DeploymentGettingStartedDetails from './DeploymentGettingStartedDetails'

import type { FC } from 'react'
import type { WithStackDeploymentRouteParamsProps } from '@/components/StackDeploymentEditor'
import type { RouteComponentProps } from 'react-router'

import './deploymentGettingStarted.scss'

type QueryParams = {
  regionId?: string
  deploymentId: string
  snapshotName?: string
}

export type StateProps = {
  instanceType: SliderInstanceType
  stackDeployment: StackDeployment | undefined
  deploymentTemplate?: DeploymentTemplateInfoV2
  resetPasswordStatus: AsyncRequestState
  credentials: ClusterCredentials | undefined
  isAnyAdminConsole: boolean
  match: RouteComponentProps<QueryParams>['match']
  profile?: UserProfile | null
}

export type DispatchProps = {
  resetPassword: (deployment: StackDeployment) => void
}

export type ConsumerProps = WithStackDeploymentRouteParamsProps

type Props = StateProps &
  DispatchProps &
  ConsumerProps & {
    stackDeployment: StackDeployment
    deploymentTemplate: DeploymentTemplateInfoV2
    resetPasswordStatus: AsyncRequestState
    instanceType: GettingStartedType
    resetPassword: (deployment: StackDeployment) => void
    onResetPassword: () => void
    isAnyAdminConsole: boolean
    match: RouteComponentProps<QueryParams>['match']
  }

const DeploymentGettingStarted: FC<Props> = ({
  stackDeployment,
  credentials,
  deploymentTemplate,
  instanceType,
  isAnyAdminConsole,
  profile,
}) => {
  const [_, flags] = useFlagsWhenLoaded()

  const {
    euiTheme: {
      size: { xxxxl: euiSizeXXXXL },
      breakpoint: { m: euiBreakpointM },
    },
  } = useEuiTheme()

  const isStopped = isEsStopped({ deployment: stackDeployment })
  const isStopping = isEsStopping({ deployment: stackDeployment })
  const hidden = isHidden({ deployment: stackDeployment })

  // We don't need to show this for deployments that are deleted
  if (isStopping || isStopped || hidden) {
    return null
  }

  const { guidedOnboarding: showGuidedOnboardingPage, deeplinkToSecurity, deeplinkToSearch } = flags

  const linkInfo =
    instanceType === `elasticsearch`
      ? []
      : getLinks({ deployment: stackDeployment, show: instanceType })

  const showDeploymentCompletedMessage = isEveryResourceStarted({ deployment: stackDeployment })

  const changingPlan = isAnyResourceChanging({ deployment: stackDeployment })

  const planInProgress =
    (linkInfo.length > 0 && !linkInfo[0]?.available && changingPlan) || changingPlan

  const disabled = linkInfo.length > 0 && !linkInfo[0]?.available && !changingPlan

  const kibanaResource = getFirstSliderClusterFromGet({
    deployment: stackDeployment,
    sliderInstanceType: `kibana`,
  })

  const isKibanaUnhealthy =
    kibanaResource !== null && !hasHealthyResourcePlan({ resource: kibanaResource })

  const createFailed =
    (!planInProgress && hasFailedCreatePlan({ deployment: stackDeployment })) ||
    (!planInProgress && isKibanaUnhealthy)

  const discoveryUseCase = getUserUsecase(profile!)

  const deepLink = kibanaGettingStartedUrl({
    resource: kibanaResource,
    showGuidedOnboardingPage,
    discoveryUseCase: discoveryUseCase || undefined,
    deeplinkToSecurity,
    deeplinkToSearch,
  })

  const paddingSize = euiSizeXXXXL

  return (
    <EuiSplitPanel.Outer
      grow={true}
      css={{
        maxWidth: euiBreakpointM,
        margin: 'auto',
        padding: paddingSize,
        marginTop: 200,
      }}
    >
      <EuiSplitPanel.Inner>
        <DeploymentGettingStartedDetails
          planFailed={createFailed}
          disabled={disabled}
          credentials={credentials}
          instanceType={instanceType}
          planInProgress={planInProgress}
          deployment={stackDeployment}
          deploymentTemplate={deploymentTemplate}
          isAnyAdminConsole={isAnyAdminConsole}
        />
      </EuiSplitPanel.Inner>
      <EuiSplitPanel.Inner>
        <DeploymentGettingStartedHeader
          planInProgress={planInProgress}
          stackDeployment={stackDeployment}
          createFailed={createFailed}
          showDeploymentCompletedMessage={showDeploymentCompletedMessage}
          deepLink={deepLink}
          disabled={disabled}
        />
      </EuiSplitPanel.Inner>
    </EuiSplitPanel.Outer>
  )
}

export default DeploymentGettingStarted
