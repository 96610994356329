/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { jsx } from '@emotion/react'
import { FormattedMessage } from 'react-intl'

import { UseCaseOptionsType } from '@modules/discovery-questions-lib/utils'
import OptionSelectorFormStep from '@modules/cui/Step/OptionSelectorFormStep'

import type { FunctionComponent } from 'react'

type Props = {
  useCase: UseCaseOptionsType | undefined
  onChangeUseCase: (value: UseCaseOptionsType) => void
  onUseCaseConfirmed: () => void
}

const options = [
  {
    key: UseCaseOptionsType.search,
    label: <FormattedMessage id='discovery-questions-use-case.radio1' defaultMessage='Search' />,
    description: (
      <FormattedMessage
        id='discovery-questions-use-case.radio1-sub'
        defaultMessage='Develop your own search use cases with Elasticsearch APIs, storage, search, and analytics capabilities.'
      />
    ),
  },
  {
    key: UseCaseOptionsType.observability,
    label: (
      <FormattedMessage id='discovery-questions-use-case.radio2' defaultMessage='Observability' />
    ),
    description: (
      <FormattedMessage
        id='discovery-questions-use-case.radio2-sub'
        defaultMessage='Collect and analyze your logs, APM data, and traces.'
      />
    ),
  },
  {
    key: UseCaseOptionsType.security,
    label: <FormattedMessage id='discovery-questions-use-case.radio3' defaultMessage='Security' />,
    description: (
      <FormattedMessage
        id='discovery-questions-use-case.radio3-sub'
        defaultMessage='Detect and respond to threats by unifying your SIEM, endpoint, and cloud asset security data.'
      />
    ),
  },
]

const UseCase: FunctionComponent<Props> = ({
  useCase,
  onChangeUseCase,
  onUseCaseConfirmed,
}: Props) => (
  <OptionSelectorFormStep
    stepTitle={
      <FormattedMessage
        id='discovery-questions-use-case.title'
        defaultMessage='Which use case are you looking to try first?'
      />
    }
    stepDescription={
      <FormattedMessage
        id='discovery-questions-use-case.subtitle'
        defaultMessage="We'll help you get set up with a relevant Elastic solution."
      />
    }
    options={options}
    value={useCase}
    onChange={onChangeUseCase}
    onSubmit={onUseCaseConfirmed}
  />
)

export default UseCase
