/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'

import {
  useListElasticsearchProjectsQuery,
  useListObservabilityProjectsQuery,
  useListSecurityProjectsQuery,
} from '@modules/project-admin-lib/hooks/list'
import type {
  NormalisedResourceRoleAssignment,
  ResourceType,
} from '@modules/role-assignments-lib/types'
import type { MemberOrApiKey } from '@modules/role-assignments-components/ProjectRoleAssignmentsSummary'
import ProjectRoleAssignmentsSummary from '@modules/role-assignments-components/ProjectRoleAssignmentsSummary'
import { useSearchOrganizationDeploymentsQuery } from '@modules/cloud-lib/deployments/hooks'

import type { FunctionComponent } from 'react'

const AdminProjectRoleAssignmentsSummary: FunctionComponent<{
  memberOrApiKey: MemberOrApiKey
  organizationId: string
  roleAssignments: Array<NormalisedResourceRoleAssignment<ResourceType>>
}> = ({ memberOrApiKey, organizationId, roleAssignments }) => {
  const deploymentQuery = useSearchOrganizationDeploymentsQuery(organizationId)
  const elasticsearchQuery = useListElasticsearchProjectsQuery({ organization_id: organizationId })
  const observabilityQuery = useListObservabilityProjectsQuery({ organization_id: organizationId })
  const securityQuery = useListSecurityProjectsQuery({ organization_id: organizationId })

  return (
    <ProjectRoleAssignmentsSummary
      memberOrApiKey={memberOrApiKey}
      userDeploymentsAndProjectsQueryResults={[
        {
          resourceType: 'deployment',
          data: deploymentQuery.data?.deployments,
          isLoading: deploymentQuery.isLoading,
          error: deploymentQuery.error,
        },
        {
          resourceType: 'elasticsearch',
          data: elasticsearchQuery.data?.items,
          isLoading: elasticsearchQuery.isLoading,
          error: elasticsearchQuery.error,
        },
        {
          resourceType: 'observability',
          data: observabilityQuery.data?.items,
          isLoading: observabilityQuery.isLoading,
          error: observabilityQuery.error,
        },
        {
          resourceType: 'security',
          data: securityQuery.data?.items,
          isLoading: securityQuery.isLoading,
          error: securityQuery.error,
        },
      ]}
      roleAssignments={roleAssignments}
    />
  )
}

export default AdminProjectRoleAssignmentsSummary
