/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useContext, useEffect, useState } from 'react'

import { useFlagsWhenLoaded } from '@modules/launchdarkly'
import { useGetSaasCurrentUserMfaDevicesQuery } from '@modules/cloud-lib/users/hooks/mfa'
import AuthContext from '@modules/auth/context'

import EnableMfaPage from '../EnableMfaPage'
import { isMfaEnforceable, meetsMfaRequirements } from '../lib'

const MfaEnforcementMiddleware: React.FunctionComponent = ({ children }) => {
  const [, { mfaEnforced }] = useFlagsWhenLoaded()
  const [showMfaPage, setShowMfaPage] = useState<boolean | null>(null)

  const {
    authContext: { method: authMethod },
  } = useContext(AuthContext)

  const devicesQuery = useGetSaasCurrentUserMfaDevicesQuery({
    enabled: isMfaEnforceable({ mfaEnforced, authMethod }),
  })
  const devices = devicesQuery.data?.mfa_devices

  // initialise modal once we have everything ready
  useEffect(() => {
    if (showMfaPage === null) {
      const meetsRequirements = meetsMfaRequirements({ mfaEnforced, authMethod, devices })

      if (meetsRequirements !== null) {
        setShowMfaPage(!meetsRequirements)
      }
    }
  }, [showMfaPage, mfaEnforced, authMethod, devices])

  if (showMfaPage) {
    return <EnableMfaPage devices={devices} onContinue={() => setShowMfaPage(false)} />
  }

  return <Fragment>{children}</Fragment>
}

export default MfaEnforcementMiddleware
