/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useMutation, useQuery } from 'react-query'

import {
  deleteDomainClaim,
  generateDomainVerificationCode,
  getDomainClaims,
  verifyDomainClaim,
} from '@modules/security-idp-api'
import { queryClient } from '@modules/query'

const getDomainsKey = ['organization', 'domains', 'domain-claims']

export const useGetDomainsQuery = (organizationId: string | undefined) =>
  useQuery({
    enabled: !!organizationId,
    queryKey: getDomainsKey,
    queryFn: () => getDomainClaims(organizationId as string),
  })

export const useGenerateDomainVerificationCode = () =>
  useMutation({
    mutationFn: ({ organizationId, domainName }: { organizationId: string; domainName: string }) =>
      generateDomainVerificationCode(organizationId, domainName),
  })

export const useVerifyDomainClaim = () =>
  useMutation({
    mutationFn: ({ organizationId, domainName }: { organizationId: string; domainName: string }) =>
      verifyDomainClaim(organizationId, domainName),
    onSuccess: () => {
      queryClient.invalidateQueries(getDomainsKey)
    },
  })

export const useDeleteDomainClaim = () =>
  useMutation({
    mutationFn: ({ organizationId, domainName }: { organizationId: string; domainName: string }) =>
      deleteDomainClaim(organizationId, domainName),
    onSuccess: () => {
      queryClient.invalidateQueries(getDomainsKey)
    },
  })
