/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton, EuiCopy, EuiFlexGroup } from '@elastic/eui'

import { useShowToastOnce } from '@modules/cui/Toasts'

import { customerEngineeringEmailAddress } from '@/constants/email'

export const useEmailVerificationToast = () =>
  useShowToastOnce({
    family: `email-verification`,
    color: `success`,
    title: <FormattedMessage id='email-verification.success' defaultMessage='Email sent' />,
    text: (
      <Fragment>
        <p>
          <FormattedMessage
            id='email-verification.email-sent'
            defaultMessage='For additional assistance, contact customer engineering: <a>{email}</a>'
            values={{
              email: customerEngineeringEmailAddress,
              a: (content) => <a href={`mailto:${customerEngineeringEmailAddress}`}>{content}</a>,
            }}
          />
        </p>

        <EuiFlexGroup justifyContent='flexEnd'>
          <EuiCopy textToCopy={customerEngineeringEmailAddress}>
            {(copy) => (
              <EuiButton iconType='copyClipboard' onClick={copy}>
                <FormattedMessage
                  id='deployment-overview.customer-engineer-callout-copy'
                  defaultMessage='Copy email'
                />
              </EuiButton>
            )}
          </EuiCopy>
        </EuiFlexGroup>
      </Fragment>
    ),
    toastLifeTimeMs: 10000,
  })
