/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { orderBy } from 'lodash'

import { EuiText } from '@elastic/eui'

import { CuiTable } from '@modules/cui/Table'
import type { CuiTableColumn } from '@modules/cui/Table'
import type { Price } from '@modules/billing-api/v1/types'

interface Props {
  prices: Price[]
  isLoading: boolean
}

const AddonList = ({ prices, isLoading }: Props) => {
  const columns: Array<CuiTableColumn<Price>> = [
    {
      label: <FormattedMessage id='pricing-list.serverless.addon-column' defaultMessage='Add-on' />,
      render: ({ name }) => name,
    },
    {
      label: <FormattedMessage id='pricing-list.serverless.price-column' defaultMessage='Price' />,
      render: ({ unit_amount }) => (
        <EuiText size='s'>
          <code>${Number(unit_amount).toFixed(4)}</code>
        </EuiText>
      ),
      width: '90px',
      align: 'right',
    },
    {
      label: <FormattedMessage id='pricing-list.serverless.unit-column' defaultMessage='Unit' />,
      render: ({ unit, pricing_period }) => {
        if (!unit) {
          return null
        }

        return unit + (pricing_period ? `-${pricing_period}` : '')
      },
      width: '180px',
      align: 'left',
    },
  ]

  return (
    <CuiTable
      initialLoading={isLoading}
      rows={orderBy(prices, ['product_type', 'unit_amount'])}
      columns={columns}
      data-test-id='addon-list'
    />
  )
}

export default AddonList
