/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { isEmpty, without } from 'lodash'
import moment from 'moment'
import React, { Fragment } from 'react'
import { defineMessages, FormattedDate, FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiButton,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiCallOut,
  EuiFilePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormHelpText,
  EuiFormRow,
  EuiSpacer,
  EuiSteps,
  EuiTabbedContent,
  EuiText,
  EuiTextColor,
  EuiTitle,
} from '@elastic/eui'

import type { DirectTrustRelationship, TrustedCertificate } from '@modules/cloud-api/v1/types'
import { CuiAlert } from '@modules/cui/Alert'
import type { CuiTableColumn } from '@modules/cui/Table'
import { CuiTable } from '@modules/cui/Table'
import { CuiTimeAgo } from '@modules/cui/TimeAgo'
import history from '@modules/utils/history'

import CustomTextPopover from '@/components/CustomTextPopover'
import DocLink from '@/components/DocLink'
import { shortDate } from '@/config/dates'
import { readFile } from '@/lib/fileHelpers'
import { createDeploymentTrustRelationshipUrl, securityUrl } from '@/lib/urlBuilder'

import {
  createUpdateTrustRequestFromGetResponse,
  getTrustLevelFromRelationship,
  getTrustRelationshipFromDeployment,
  isApiKeysRelationship,
  isDirectRelationship,
} from '../../../lib/stackDeployments/trustRelationships'
import EnterEnvironmentId from '../components/EnterEnvironmentId'
import EnterName from '../components/EnterName'
import EnterOrganizationId from '../components/EnterOrganizationId'
import EnterOtherAccountRemoteDeployments from '../components/EnterOtherAccountRemoteDeployments'
import ProceedToAddingApiKeys from '../components/ProceedToAddingApiKeys'
import TrustLevelSelector from '../components/TrustLevelSelector'
import TrustRelationshipNavigationSteps from '../components/TrustRelationshipNavigationSteps'
import {
  flattenTrustedEnvs,
  getTargetEnvironmentType as getTargetEnvironmentTypeHelper,
} from '../helpers'

import AlsoTrustedByDeploymentsList from './components/AlsoTrustedByDeploymentsList'
import DirectTrustClusterIds from './components/DirectTrustClusterIds'
import Fingerprint from './components/Fingerprint'
import DirectTrustRelationshipInstructions from './Instructions'
import ManageDirectTrustRelationshipApiKeys from './ManageDirectTrustRelationshipApiKeys'

import type { TrustLevel } from '@/lib/stackDeployments/selectors/crossClusterReplication'
import type { WrappedComponentProps } from 'react-intl'
import type { ApiKey } from '../types'
import type { AllProps as Props } from './types'

interface State {
  name: string
  scopeId: string
  trustLevel: TrustLevel | null
  trustedClusterIds: string[]
  additionalNodeNames: string[]
  certificates: string[]
  didCreate: boolean
  uid?: string
  apiKeys: ApiKey[]
}

const messages = defineMessages({
  organizationIdStepTitle: {
    id: 'deploymentTrustManagement.direct.stepTitles.organizationId',
    defaultMessage: 'Add organization ID',
  },
  environmentIdStepTitle: {
    id: 'deploymentTrustManagement.direct.stepTitles.environmentId',
    defaultMessage: 'Add environment ID',
  },
  certificateStepTitle: {
    id: 'deploymentTrustManagement.direct.stepTitles.certificate',
    defaultMessage: 'Add trusted CA certificate',
  },
  deploymentsStepTitle: {
    id: 'deploymentTrustManagement.direct.stepTitles.deployments',
    defaultMessage: 'Select trusted deployments',
  },
  clustersStepTitle: {
    id: 'deploymentTrustManagement.direct.stepTitles.clusters',
    defaultMessage: 'Select trusted clusters',
  },
  nameStepTitle: {
    id: 'deploymentTrustManagement.direct.stepTitles.name',
    defaultMessage: 'Name the environment',
  },
  certPrompt: {
    id: 'deploymentTrustManagement.direct.certPrompt',
    defaultMessage: 'Select or drag and drop CA certificate',
  },
})

const EXPIRING_SOON = 28 // days

type AllProps = Props & WrappedComponentProps

class ManageDirectTrustRelationship extends React.Component<AllProps, State> {
  state: State

  constructor(props: AllProps) {
    super(props)

    // has to be a relationship type context for rendering purposes
    if (!this.getTargetEnvironmentType()) {
      history.push(securityUrl(props.deployment.id))
    }

    this.state = this.createInitialState()
  }

  componentDidMount(): void {
    const { previouslyUsedEnv, fetchTrustedEnvs, trustedEnvs } = this.props

    if (previouslyUsedEnv) {
      fetchTrustedEnvs()

      if (trustedEnvs && !isEmpty(trustedEnvs)) {
        this.setPreviouslyUsedEnvDetails()
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { previouslyUsedEnv, trustedEnvs } = this.props

    if (previouslyUsedEnv && isEmpty(prevProps.trustedEnvs) && !isEmpty(trustedEnvs)) {
      // We're using a template, and the trustedEnvs were loaded in
      this.setPreviouslyUsedEnvDetails()
    }
  }

  componentWillUnmount(): void {
    this.props.resetUpdateStackDeployment()
  }

  render(): JSX.Element {
    const { isEce, deployment } = this.props
    const trustRelationshipFromState = this.getTrustRelationshipFromState()
    const trustRelationshipUnderEdit = this.getTrustRelationshipUnderEdit()

    if (
      isEce &&
      this.props.authMechanism === 'api-keys' &&
      this.getTargetEnvironmentType() === 'ess'
    ) {
      return <ProceedToAddingApiKeys deployment={deployment} />
    }

    if (this.props.authMechanism === 'api-keys') {
      return <ManageDirectTrustRelationshipApiKeys {...this.props} />
    }

    if (trustRelationshipUnderEdit && isApiKeysRelationship(trustRelationshipUnderEdit)) {
      return <ManageDirectTrustRelationshipApiKeys {...this.props} />
    }

    if (trustRelationshipFromState && this.state.didCreate) {
      return (
        <Fragment>
          <TrustRelationshipNavigationSteps currentStep={2} />
          <EuiSpacer size='xl' />
          <DirectTrustRelationshipInstructions
            deployment={this.props.deployment}
            trustRelationshipFromState={trustRelationshipFromState}
            trustRelationshipUnderEdit={trustRelationshipUnderEdit}
          />
        </Fragment>
      )
    }

    if (this.props.previouslyUsedEnv) {
      return this.renderCreateWithTemplate()
    }

    return this.getTrustRelationshipIdUnderEdit() ? this.renderEditPage() : this.renderCreatePage()
  }

  renderCreatePage(): JSX.Element {
    const {
      intl: { formatMessage },
      isEce,
    } = this.props

    const organizationIdStep = {
      title: formatMessage(messages.organizationIdStepTitle),
      children: this.renderOrganizationIdFields(),
    }

    const environmentIdStep = {
      title: formatMessage(messages.environmentIdStepTitle),
      children: this.renderEnvironmentIdFields(),
    }

    const certificateStep = {
      title: formatMessage(messages.certificateStepTitle),
      children: this.renderAddCertificate(),
    }

    const deploymentsStep = {
      title: formatMessage(messages.deploymentsStepTitle),
      children: this.renderDeploymentsFields(),
    }

    const clustersStep = {
      title: formatMessage(messages.clustersStepTitle),
      children: this.renderClustersFields(),
    }

    const nameStep = {
      title: formatMessage(messages.nameStepTitle),
      children: this.renderNameFields(),
    }

    return (
      <Fragment>
        <TrustRelationshipNavigationSteps currentStep={2} />

        <EuiSpacer />

        {this.getTargetEnvironmentType() === 'self-managed' && (
          <EuiSteps steps={[certificateStep, clustersStep, nameStep]} />
        )}

        {isEce && this.getTargetEnvironmentType() === 'ess' && (
          <EuiSteps steps={[organizationIdStep, deploymentsStep, certificateStep, nameStep]} />
        )}

        {!isEce && this.getTargetEnvironmentType() === 'ece' && (
          <EuiSteps steps={[environmentIdStep, certificateStep, deploymentsStep, nameStep]} />
        )}

        {this.renderError()}
        {this.renderButtons()}
      </Fragment>
    )
  }

  renderEditPage(): JSX.Element {
    const tabs = [
      {
        id: 'trust',
        name: (
          <FormattedMessage
            id='deploymentTrustManagement.tabs.trust'
            defaultMessage='Trust level'
          />
        ),
        content: (
          <Fragment>
            <EuiSpacer size='xl' />

            {this.getTargetEnvironmentType() === 'self-managed'
              ? this.renderClustersFields()
              : this.renderDeploymentsFieldsWithTitle()}

            <EuiSpacer size='l' />

            {this.renderError()}
            {this.renderButtons()}
          </Fragment>
        ),
      },
      {
        id: 'environment',
        name: (
          <FormattedMessage
            id='deploymentTrustManagement.tabs.environment'
            defaultMessage='Environment settings'
          />
        ),
        content: (
          <Fragment>
            <EuiSpacer size='xl' />

            {this.getTargetEnvironmentType() !== 'ess' && (
              <Fragment>
                <EuiTitle size='xs'>
                  <h2>
                    <FormattedMessage
                      id='deploymentTrustManagement.direct.environmentName'
                      defaultMessage='Environment name'
                    />
                  </h2>
                </EuiTitle>
                <EuiSpacer size='s' />
                {this.renderNameFields()}
              </Fragment>
            )}

            <EuiSpacer size='xl' />

            <EuiTitle size='xs'>
              <h2>
                <FormattedMessage
                  id='deploymentTrustManagement.direct.caCertificates'
                  defaultMessage='CA certificates'
                />
              </h2>
            </EuiTitle>
            <EuiSpacer size='s' />
            <EuiFlexGroup justifyContent='spaceBetween'>
              <EuiFlexItem>
                {this.renderExistingCertificates()}
                {this.renderAddCertificate()}
              </EuiFlexItem>
              <EuiFlexItem grow={false}>{this.renderAlsoTrustedByDeployments()}</EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size='l' />

            {this.renderError()}
            {this.renderButtons()}
          </Fragment>
        ),
      },
    ]

    return <EuiTabbedContent tabs={tabs} initialSelectedTab={tabs[0]} autoFocus='selected' />
  }

  renderCreateWithTemplate(): JSX.Element {
    return (
      <Fragment>
        <TrustRelationshipNavigationSteps currentStep={2} />
        <EuiSpacer size='m' />
        {this.renderDeploymentsFieldsWithTitle()}
        <EuiSpacer size='m' />

        {this.renderError()}
        {this.renderButtons()}
      </Fragment>
    )
  }

  renderDeploymentsFieldsWithTitle(): JSX.Element {
    return (
      <Fragment>
        <EuiTitle size='s'>
          <h4>
            <FormattedMessage {...messages.deploymentsStepTitle} />
          </h4>
        </EuiTitle>
        <EuiSpacer />
        {this.renderDeploymentsFields()}
      </Fragment>
    )
  }

  renderOrganizationIdFields(): JSX.Element | null {
    return (
      <Fragment>
        <EuiText size='s' grow={false}>
          <FormattedMessage
            id='deploymentTrustManagement.direct.organizationId.instructions'
            defaultMessage='You can find the ID on the Organization page of the environment you want to trust, next to the name of the organization.'
          />
        </EuiText>
        <EuiSpacer />
        <EuiFormRow>
          <EnterOrganizationId
            organizationId={this.state.scopeId}
            onChange={(organizationId) => {
              this.setState({ scopeId: organizationId })
            }}
          />
        </EuiFormRow>
      </Fragment>
    )
  }

  renderEnvironmentIdFields(): JSX.Element | null {
    return (
      <Fragment>
        <EuiText size='s'>
          <FormattedMessage
            id='deploymentTrustManagement.direct.environmentId.instructions'
            defaultMessage='Provide the ID of the ECE environment you want to trust. You can find the environment ID on the Trust management page, under Platform.'
          />
        </EuiText>
        <EuiSpacer />
        <EuiFormRow>
          <EnterEnvironmentId
            environmentId={this.state.scopeId}
            onChange={(organizationId) => {
              this.setState({ scopeId: organizationId })
            }}
          />
        </EuiFormRow>
      </Fragment>
    )
  }

  renderExistingCertificates(): JSX.Element | null {
    const trustRelationship = this.getTrustRelationshipUnderEdit()

    if (!trustRelationship) {
      return null // sanity
    }

    const anyExpiringSoon = trustRelationship.certificates.some((cert) => {
      const daysToExpiry = moment(cert.metadata?.valid_to).diff(moment(), 'days')
      return daysToExpiry < EXPIRING_SOON
    })

    const rows = trustRelationship.certificates.filter(({ pem }) =>
      this.state.certificates.includes(pem),
    )

    if (!rows || rows.length === 0) {
      return null
    }

    const columns: Array<CuiTableColumn<TrustedCertificate>> = [
      {
        label: (
          <FormattedMessage
            id='deploymentTrustManagement.direct.certificatesTable.fingerprintHeader'
            defaultMessage='Certificate fingerprint'
          />
        ),
        render: (cert) => <Fingerprint fingerprint={cert.metadata?.fingerprint} />,
      },
      {
        label: (
          <FormattedMessage
            id='deploymentTrustManagement.direct.certificatesTable.expiresHeader'
            defaultMessage='Expiration date'
          />
        ),
        render: (cert) => {
          const daysToExpiry = moment(cert.metadata?.valid_to).diff(moment(), 'days')

          if (daysToExpiry < 0) {
            return (
              <EuiTextColor color='danger'>
                <FormattedMessage
                  id='deploymentTrustManagement.direct.certificatesTable.expired'
                  defaultMessage='Expired'
                />
              </EuiTextColor>
            )
          }

          if (daysToExpiry < EXPIRING_SOON) {
            return (
              <EuiTextColor color='warning'>
                <CuiTimeAgo date={cert.metadata?.valid_to} />
              </EuiTextColor>
            )
          }

          return <FormattedDate value={cert.metadata?.valid_to} {...shortDate} />
        },
      },
      {
        label: (
          <FormattedMessage
            id='deploymentTrustManagement.direct.certificatesTable.addedHeader'
            defaultMessage='Added'
          />
        ),
        render: (cert) => <CuiTimeAgo date={cert.metadata?.valid_from} />,
      },
      {
        actions: true,
        render: ({ pem }) => (
          <EuiButtonIcon
            iconType='cross'
            onClick={() => this.setState({ certificates: without(this.state.certificates, pem) })}
          />
        ),
        align: `right`,
      },
    ]

    return (
      <div style={{ maxWidth: `48rem` }}>
        <EuiText size='s'>
          To maintain the trust relationship with the {trustRelationship.name} environment, you need
          at least one active CA certificate from that environment.{' '}
          {trustRelationship.type === 'ECE' && (
            <span>
              You can find CA certificates under Platform, on the Trust management page of the
              trusted environment.
            </span>
          )}
        </EuiText>
        {anyExpiringSoon && (
          <Fragment>
            <EuiSpacer size='m' />
            <EuiCallOut
              color='danger'
              title={
                <FormattedMessage
                  id='deploymentTrustManagement.direct.certificate.expiryWarning.title'
                  defaultMessage='Expired CA certificates'
                />
              }
            >
              <EuiText size='s'>
                <FormattedMessage
                  id='deploymentTrustManagement.direct.certificate.expiryWarning.body'
                  defaultMessage='At least one of the CA certificates has expired or is expiring soon. Upload the latest certificates available from the trusted environment to keep the trust relationship active, and remove any old ones.'
                />
              </EuiText>
            </EuiCallOut>
          </Fragment>
        )}
        <EuiSpacer size='s' />
        <CuiTable<TrustedCertificate> rows={rows} columns={columns} />
        <EuiSpacer size='l' />
      </div>
    )
  }

  renderAddCertificate(): JSX.Element | null {
    const {
      intl: { formatMessage },
    } = this.props

    return (
      <Fragment>
        <EuiText size='s' grow={false}>
          {!this.getTrustRelationshipIdUnderEdit() && this.getTargetEnvironmentType() === 'ess' && (
            <Fragment>
              <FormattedMessage
                id='deploymentTrustManagement.direct.certificate.instructions.ess'
                defaultMessage='Upload the CA certificates from the Elastic Cloud deployments you chose to trust in the previous step. You can find them on the Security page of each deployment.'
              />
            </Fragment>
          )}
          {!this.getTrustRelationshipIdUnderEdit() && this.getTargetEnvironmentType() === 'ece' && (
            <Fragment>
              <FormattedMessage
                id='deploymentTrustManagement.direct.certificate.instructions.ece'
                defaultMessage='Upload the CA certificate of the Elastic Cloud Enterprise environment that you want to trust. You can find the CA certificate from Platform > Settings > TLS certificates > Proxy > Show certificate chain.'
              />
            </Fragment>
          )}
          {!this.getTrustRelationshipIdUnderEdit() &&
            this.getTargetEnvironmentType() === 'self-managed' && (
              <FormattedMessage
                id='deploymentTrustManagement.direct.certificate.instructions.selfManaged'
                defaultMessage='Upload the {caCertificate} of the self-managed Elasticsearch environment that you want to trust.'
                values={{
                  caCertificate: (
                    <CustomTextPopover
                      buttonText={
                        <FormattedMessage
                          id='ca-certificate.text'
                          defaultMessage='CA certificate'
                        />
                      }
                    >
                      <EuiFormHelpText>
                        <FormattedMessage
                          id='ca-certificate.description'
                          defaultMessage='In secured environments, Elasticsearch nodes use certificates to identify themselves when communicating with other nodes. {learnMore}'
                          values={{
                            learnMore: (
                              <DocLink link='generateCertificateAuthorityDocLink'>
                                <FormattedMessage
                                  id='ca-certificate.learn-more'
                                  defaultMessage='Learn more'
                                />
                              </DocLink>
                            ),
                          }}
                        />
                      </EuiFormHelpText>
                    </CustomTextPopover>
                  ),
                }}
              />
            )}
        </EuiText>
        {!this.getTrustRelationshipIdUnderEdit() && <EuiSpacer />}
        <EuiFormRow>
          <EuiFilePicker
            accept='application/x-x509-ca-cert'
            multiple={this.getTargetEnvironmentType() === 'ess'}
            initialPromptText={formatMessage(messages.certPrompt)}
            onChange={(files) => {
              if (files?.length) {
                const certPromises: Array<Promise<string>> = Array.from(files).map(readFile)
                Promise.all(certPromises).then((newCerts) =>
                  this.setState({ certificates: [...this.state.certificates, ...newCerts] }),
                )
              } else {
                this.setState({ certificates: [] })
              }
            }}
          />
        </EuiFormRow>
      </Fragment>
    )
  }

  renderDeploymentsFields(): JSX.Element | null {
    const { trustLevel, trustedClusterIds } = this.state

    return (
      <Fragment>
        <EuiFormRow>
          <TrustLevelSelector
            trustLevel={trustLevel}
            onChange={(trustLevel) => {
              this.setState({ trustLevel })
            }}
          />
        </EuiFormRow>

        {trustLevel === `specific` && (
          <Fragment>
            <EuiSpacer />

            <EnterOtherAccountRemoteDeployments
              trustedClusterIds={trustedClusterIds}
              onChange={(trustedClusterIds) => this.setState({ trustedClusterIds })}
            />
          </Fragment>
        )}
      </Fragment>
    )
  }

  renderClustersFields(): JSX.Element | null {
    return (
      <Fragment>
        <EuiSpacer size='s' />
        <DirectTrustClusterIds
          scopeId={this.state.scopeId}
          trustLevel={this.state.trustLevel}
          trustedClusterIds={this.state.trustedClusterIds}
          additionalNodeNames={this.state.additionalNodeNames}
          onChange={(delta) => this.setState({ ...delta })}
        />
      </Fragment>
    )
  }

  renderAlsoTrustedByDeployments(): JSX.Element | null {
    return (
      <AlsoTrustedByDeploymentsList
        certificates={this.getExistingCertificates()}
        trustRelationship={this.getTrustRelationshipUnderEdit()}
      />
    )
  }

  renderNameFields(): JSX.Element | null {
    return (
      <EuiFormRow>
        <EnterName
          name={this.state.name}
          onChange={(name) => {
            this.setState({ name })
          }}
        />
      </EuiFormRow>
    )
  }

  renderError(): JSX.Element | null {
    const { updateStackDeploymentRequest } = this.props

    if (!updateStackDeploymentRequest.error) {
      return null
    }

    return (
      <EuiFlexItem grow={false}>
        <CuiAlert type='danger' data-test-id='update-deployment-request-error'>
          {updateStackDeploymentRequest.error}
        </CuiAlert>
      </EuiFlexItem>
    )
  }

  renderButtons(): JSX.Element {
    const { deployment, updateStackDeploymentRequest } = this.props

    const isDisabled = !this.getTrustRelationshipFromState()

    return (
      <Fragment>
        <EuiSpacer size='l' />
        <EuiFlexGroup justifyContent='flexStart'>
          <EuiFlexItem grow={false}>
            <EuiButton
              type='button'
              data-test-id='save-trust-relationship-button'
              disabled={isDisabled}
              onClick={() => this.onSave()}
              isLoading={updateStackDeploymentRequest.inProgress}
              fill={true}
            >
              {this.getTrustRelationshipIdUnderEdit() ? (
                <FormattedMessage
                  id='deploymentTrustManagement.direct.submitButton.edit'
                  defaultMessage='Save'
                />
              ) : (
                <FormattedMessage
                  id='deploymentTrustManagement.direct.submitButton.create'
                  defaultMessage='Create trust'
                />
              )}
            </EuiButton>
          </EuiFlexItem>
          {!this.getTrustRelationshipIdUnderEdit() && (
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                onClick={() =>
                  history.push(createDeploymentTrustRelationshipUrl(deployment.id, undefined))
                }
              >
                <FormattedMessage id='deploymentTrustManagement.back' defaultMessage='< Back' />
              </EuiButtonEmpty>
            </EuiFlexItem>
          )}
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty onClick={() => history.push(securityUrl(deployment.id))}>
              <FormattedMessage id='deploymentTrustManagement.cancel' defaultMessage='Cancel' />
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      </Fragment>
    )
  }

  createInitialState = (): State => {
    const trustRelationship = this.getTrustRelationshipUnderEdit()

    if (trustRelationship) {
      return {
        name: trustRelationship.name,
        trustLevel: getTrustLevelFromRelationship(trustRelationship),
        trustedClusterIds: trustRelationship.trust_allowlist || [],
        scopeId: trustRelationship.scope_id || '',
        additionalNodeNames: trustRelationship.additional_node_names || [],
        certificates: trustRelationship.certificates.map(({ pem }) => pem) || [],
        didCreate: false,
        apiKeys: [],
      }
    }

    return {
      name: ``,
      trustLevel: null,
      trustedClusterIds: [],
      scopeId: ``,
      additionalNodeNames: [],
      certificates: [],
      didCreate: false,
      apiKeys: [],
    }
  }

  getTrustRelationshipFromState(): DirectTrustRelationship | null {
    if (!this.state.name) {
      return null
    }

    if (this.state.certificates.length === 0) {
      return null
    }

    return {
      name: this.state.name,
      trust_all: this.state.trustLevel === 'all',
      trust_allowlist: this.state.trustedClusterIds,
      scope_id: this.state.scopeId,
      additional_node_names: this.state.additionalNodeNames,
      certificates: this.state.certificates.map((pem) => ({ pem })),
    }
  }

  onSave(): void {
    const { deployment, previouslyUsedEnv, updateStackDeployment } = this.props

    const trustRelationship = this.getTrustRelationshipFromState()

    if (!trustRelationship) {
      return // sanity/validation
    }

    const trustRelationshipUnderEdit = this.getTrustRelationshipUnderEdit()

    // persist or intialize non-user-facing fields
    if (trustRelationshipUnderEdit) {
      trustRelationship.uid = trustRelationshipUnderEdit.uid
      trustRelationship.type = trustRelationshipUnderEdit.type
    } else if (previouslyUsedEnv) {
      trustRelationship.uid = this.state.uid
      trustRelationship.type =
        {
          ess: 'ESS',
          ece: 'ECE',
        }[this.getTargetEnvironmentType()!] || 'generic'
    } else {
      trustRelationship.type =
        {
          ess: 'ESS',
          ece: 'ECE',
        }[this.getTargetEnvironmentType()!] || 'generic'
    }

    const payload = createUpdateTrustRequestFromGetResponse({
      deployment,
      trustRelationships: [trustRelationship],
      type: `direct`,
    })

    updateStackDeployment(payload).then(() => {
      if (!this.getTrustRelationshipIdUnderEdit()) {
        // Show instructions on create. We scroll to top to mimic a route
        // change. The instructions can't feasibly be a proper route because the
        // trust relationship creation is done via a generic deployment update
        // and doesn't directly return the new relationship's ID.
        this.setState({ didCreate: true })
        window.scrollTo(0, 0)
      } else {
        history.push(securityUrl(deployment.id))
      }
    })
  }

  getTrustRelationshipIdUnderEdit = (): string | null =>
    this.props.match.params.trustRelationshipId || null

  getTrustRelationshipUnderEdit = (): DirectTrustRelationship | null => {
    const { deployment } = this.props
    const trustRelationshipId = this.getTrustRelationshipIdUnderEdit()

    return (
      ((trustRelationshipId &&
        getTrustRelationshipFromDeployment({
          deployment,
          trustRelationshipType: 'direct',
          trustRelationshipId,
        })) as DirectTrustRelationship) || null
    )
  }

  getTargetEnvironmentType = () => {
    const trustRelationship = this.getTrustRelationshipUnderEdit()
    return getTargetEnvironmentTypeHelper(trustRelationship)
  }

  setPreviouslyUsedEnvDetails() {
    const { trustedEnvs, previouslyUsedEnv } = this.props
    const flatEnvs = flattenTrustedEnvs(trustedEnvs)
    const previouslyUsedEnvObj = flatEnvs.find((env) => env.name === previouslyUsedEnv)

    if (!previouslyUsedEnvObj) {
      return
    }

    if (!isDirectRelationship(previouslyUsedEnvObj)) {
      return
    }

    this.setState({
      name: previouslyUsedEnvObj.name || ``,
      trustLevel: null, // default this to nothing because we want the user to select this anew
      trustedClusterIds: [], // default this to nothing because we want the user to select this anew
      scopeId: previouslyUsedEnvObj.scope_id || '',
      additionalNodeNames: previouslyUsedEnvObj.additional_node_names || [],
      certificates: previouslyUsedEnvObj.certificates.map(({ pem }) => pem) || [],
      didCreate: false,
      uid: previouslyUsedEnvObj.uid,
    })
  }

  getExistingCertificates = (): TrustedCertificate[] | null => {
    const trustRelationship = this.getTrustRelationshipUnderEdit()

    if (!trustRelationship) {
      return null // sanity
    }

    const certificates = trustRelationship.certificates.filter(({ pem }) =>
      this.state.certificates.includes(pem),
    )

    if (!certificates || certificates.length === 0) {
      return null
    }

    return certificates
  }
}

export default injectIntl(ManageDirectTrustRelationship)
