/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { SaasAuthMfaDeviceResponse } from '@modules/cloud-api/v1/types'
import { hasStrongMfaDevice } from '@modules/mfa-lib/devices'
import type { AuthContextData } from '@modules/auth/context'

export function isMfaEnforceable({
  mfaEnforced,
  authMethod,
}: {
  mfaEnforced: boolean
  authMethod: AuthContextData['method']
}): boolean {
  if (!mfaEnforced) {
    return false // feature flag
  }

  if (authMethod !== 'username-and-password') {
    return false // only enforce if they logged in with traditional credentials instead of e.g. a third party IdP.
  }

  return true
}

export function meetsMfaRequirements({
  mfaEnforced,
  authMethod,
  devices,
}: {
  mfaEnforced: boolean
  authMethod: AuthContextData['method']
  devices: SaasAuthMfaDeviceResponse[] | undefined
}): boolean | null {
  if (!devices) {
    return null
  }

  return !isMfaEnforceable({ mfaEnforced, authMethod }) || hasStrongMfaDevice(devices)
}
