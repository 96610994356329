/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import { useResendVerificationEmailMutation } from '@modules/cloud-lib/users/hooks'
import { addToast } from '@modules/cui/Toasts'
import history from '@modules/utils/history'

import EmailVerification from './EmailVerification'
import { useEmailVerificationToast } from './hook'

import type { FC } from 'react'
import type { RouteComponentProps } from 'react-router-dom'

interface Props {
  onCancel: () => void
  email: string
  isPasswordNotice?: boolean
  location?: RouteComponentProps['location']
  resendMailCoolingOffPeriodInSeconds?: number
}

const toastText = {
  emailVerificationSent: {
    color: 'success',
    title: (
      <FormattedMessage
        id='email-verification.success-notification-title'
        defaultMessage='Email verification sent'
      />
    ),
    text: (
      <FormattedMessage
        id='email-verification.success-notification-text'
        defaultMessage="Please check your inbox. If you don't see it, you may need to check your spam folder."
      />
    ),
  },
  resendEmailVerificationFailed: {
    color: 'danger',
    title: (
      <FormattedMessage
        id='email-verification.failed-notification-title'
        defaultMessage='Resend email verification failed'
      />
    ),
    text: (
      <FormattedMessage
        id='email-verification.failed-notification-text'
        defaultMessage='Something went wrong. Please try again'
      />
    ),
  },
}

const QueryingEmailVerification: FC<Props> = ({ email, isPasswordNotice, location, onCancel }) => {
  useEffect(() => {
    if (location) {
      history.push(`${location.pathname}?verifyingEmail=true`)
    }
  }, [])

  useEmailVerificationToast()

  const {
    mutate: resendVerificationEmail,
    isLoading: isResendVerificationEmailLoading,
    isSuccess: isResendVerificationEmailSuccess,
  } = useResendVerificationEmailMutation()

  return (
    <EmailVerification
      email={email}
      emailSent={isResendVerificationEmailSuccess}
      isPasswordNotice={isPasswordNotice}
      loading={isResendVerificationEmailLoading}
      onResendButtonClicked={handleResendButtonClicked}
      onCancel={onCancel}
    />
  )

  function handleResendButtonClicked() {
    try {
      resendVerificationEmail({
        email,
      })

      addToast(toastText.emailVerificationSent)
    } catch (e) {
      addToast(toastText.resendEmailVerificationFailed)
    }
  }
}

export default QueryingEmailVerification
