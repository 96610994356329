/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { ProjectType } from '@modules/ui-types/projects'
import type { ProjectStat } from '@modules/autoops-api/types'

export const STATS_BY_PROJECT_TYPE: Record<ProjectType, ProjectStat[]> = {
  elasticsearch: [
    'PROJECT_QUERY_RATE_AND_LATENCY',
    'PROJECT_VCU_USAGE',
    'PROJECT_STORAGE_RETAINED',
  ],
  observability: ['PROJECT_INGEST_RATE', 'PROJECT_STORAGE_RETAINED'],
  security: ['PROJECT_INGEST_RATE', 'PROJECT_STORAGE_RETAINED'],
}

const DATE_RANGE_OPTIONS = ['now-3h', 'now-6h', 'now-12h', 'now-24h', 'now-2d', 'now-7d'] as const

export type DateRangeOption = (typeof DATE_RANGE_OPTIONS)[number]

export const DEFAULT_RANGE_OPTION: DateRangeOption = 'now-24h'
