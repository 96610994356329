/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router'

import { EuiButton } from '@elastic/eui'

import type { DeploymentCreateRequest, StackVersionConfig } from '@modules/cloud-api/v1/types'
import type { Region, StackDeploymentCreateRequest } from '@modules/ui-types'
import {
  usePostDeploymentCreationMutation,
  usePostInstantDeploymentMutation,
} from '@modules/deployment-creation-api/hooks'
import { useCreateOrganizationMutation, useProfile } from '@modules/profile-lib/hooks'
import { deploymentTypeOnboardingStartingUrl } from '@modules/project-creation-lib/urls'
import {
  useOnboardingMetadataFromLocalStorage,
  useOnboardingToken,
} from '@modules/discovery-questions-lib/hooks'
import { ResourceType } from '@modules/discovery-questions-lib/steps'

// eslint-disable-next-line import/no-restricted-paths
import { getCreatePayload, getClaimDeploymentRequestPayload } from '@/lib/stackDeployments/crud'

import type { FunctionComponent } from 'react'

type Props = {
  editorState: StackDeploymentCreateRequest
  disabled?: boolean
  resetCreateDeployment?: () => void
  region: Region | undefined
  stackVersions?: StackVersionConfig[] | undefined
  onDeploymentCreation: () => Promise<void>
}

const CreateDeploymentButton: FunctionComponent<Props> = (props) => {
  const { editorState, stackVersions, region, onDeploymentCreation, disabled } = props
  const profile = useProfile()
  const history = useHistory()

  const onboardingToken = useOnboardingToken()

  const [_, setOnboardingMetadata] = useOnboardingMetadataFromLocalStorage()

  const onDeploymentCreationSuccess = (deployment) => {
    if (deployment && deployment.id && !Array.isArray(onboardingToken)) {
      setOnboardingMetadata({
        type: ResourceType.Stateful,
        token: onboardingToken,
        resource_id: deployment.id,
      })
      history.push(deploymentTypeOnboardingStartingUrl(deployment.id, onboardingToken ?? null))
    }
  }

  const claimInstantDeploymentSuccess = (payload) => {
    const deployment = payload?.deployment_get_response
    onDeploymentCreationSuccess(deployment)
  }

  const claimInstantDeploymentError = () => {
    if (profile && !profile.organization_id) {
      //  TODO check error is 404
      createOrgMutate(undefined, {
        onSuccess: () =>
          postDeploymentCreationMutate(
            {
              body: createDeploymentPayload as DeploymentCreateRequest,
            },
            {
              onSuccess: onDeploymentCreationSuccess,
            },
          ),
      })
      return
    }

    postDeploymentCreationMutate(
      {
        body: createDeploymentPayload as DeploymentCreateRequest,
      },
      {
        onSuccess: onDeploymentCreationSuccess,
      },
    )
  }

  const { mutate: postDeploymentCreationMutate, isLoading: deploymentIsLoading } =
    usePostDeploymentCreationMutation({
      onMutate: onDeploymentCreation,
    })

  const { mutate: postInstantDeploymentMutate, isLoading: instantDeploymentIsLoading } =
    usePostInstantDeploymentMutation({
      onSuccess: claimInstantDeploymentSuccess,
      onError: claimInstantDeploymentError,
      onMutate: onDeploymentCreation,
    })

  const { mutate: createOrgMutate, isLoading: createOrgIsLoading } = useCreateOrganizationMutation(
    {},
  )

  if (!region) {
    return null
  }

  const createDeploymentPayload = getCreatePayload({
    region,
    editorState,
    stackVersions,
  })

  const claimInstantDeployment = async () => {
    const claimDeploymentRequestPayload = getClaimDeploymentRequestPayload(
      createDeploymentPayload as DeploymentCreateRequest,
    )

    if (claimDeploymentRequestPayload) {
      postInstantDeploymentMutate({
        body: claimDeploymentRequestPayload,
      })
    }
  }

  return (
    <EuiButton
      data-test-id='signup.discovery-questions.launch'
      className='create-deployment-action-button'
      fill={true}
      isLoading={instantDeploymentIsLoading || deploymentIsLoading || createOrgIsLoading}
      onClick={() => claimInstantDeployment()}
      disabled={disabled}
    >
      <FormattedMessage id='create-deployment-modules.create-deployment' defaultMessage='Launch' />
    </EuiButton>
  )
}

export default CreateDeploymentButton
