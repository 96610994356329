/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useEffect, useState } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

import type { EuiStepsProps } from '@elastic/eui'
import {
  EuiButton,
  EuiButtonEmpty,
  EuiCode,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSkeletonRectangle,
  EuiSkeletonText,
  EuiSpacer,
  EuiSteps,
  EuiText,
  useGeneratedHtmlId,
} from '@elastic/eui'

import {
  useActivateSaasCurrentUserMfaDeviceMutation,
  useEnrollSaasCurrentUserMfaDeviceMutation,
} from '@modules/cloud-lib/users/hooks/mfa'
import { CuiAlert } from '@modules/cui/Alert'
import CopyButton from '@modules/cui/CopyButton'

import genericMessages from '../../mfa-lib/genericMessages'

const messages = defineMessages({
  qrCodeTitle: {
    id: 'mfaEnforcement.google.qrCode.title',
    defaultMessage: 'Scan QR code',
  },
  enterCodeTitle: {
    id: 'mfaEnforcement.google.enterCode.title',
    defaultMessage: 'Enter verification code',
  },
  qrCodeLabel: {
    id: 'mfaEnforcement.google.enterCode.instructions',
    defaultMessage: 'Enter the 6-digit code generated by the app.',
  },
})

const SetupAuthenticatorDevice: React.FunctionComponent<{
  closeModal: () => void
}> = ({ closeModal }) => {
  const { formatMessage } = useIntl()
  const modalTitleId = useGeneratedHtmlId()
  const [code, setCode] = useState('')

  const { data: device, mutate: enrollMutate } = useEnrollSaasCurrentUserMfaDeviceMutation()

  useEffect(() => enrollMutate({ type: 'GOOGLE' }), [enrollMutate])

  const activateDeviceMutation = useActivateSaasCurrentUserMfaDeviceMutation()

  const steps: EuiStepsProps['steps'] = [
    {
      title: formatMessage(messages.qrCodeTitle),
      children: (
        <Fragment>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiText size='s'>
                <FormattedMessage
                  id='mfaEnforcement.google.qrCode.instructions'
                  defaultMessage='<p>Open your preferred authentication app on your mobile device and scan this QR code.</p><p>If you are unable to scan the image, enter either the URL or code provided below manually to your app. </p>'
                  values={{ p: (content) => <p>{content}</p> }}
                />
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              {device ? (
                <img
                  style={{ width: 164, height: 164, marginTop: -16, marginRight: -16 }}
                  src={device.qr_code?.url}
                />
              ) : (
                <EuiSkeletonRectangle
                  width={128}
                  height={128}
                  style={{ marginBottom: 16, marginLeft: 16 }}
                />
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
          <div>
            <EuiText size='xs'>
              <strong>Code</strong>
            </EuiText>
            {device ? (
              <EuiFlexGroup gutterSize='xs' alignItems='baseline'>
                <EuiFlexItem grow={true}>
                  <EuiCode style={{ overflowWrap: 'anywhere', wordBreak: 'break-all' }}>
                    {device.totp_secret?.shared_secret}
                  </EuiCode>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <CopyButton value={device.totp_secret?.shared_secret || ''} />
                </EuiFlexItem>
              </EuiFlexGroup>
            ) : (
              <EuiSkeletonText lines={1} />
            )}
          </div>
          <EuiSpacer size='m' />
          <div style={{ maxWidth: '100%' }}>
            <EuiText size='xs'>
              <strong>URL</strong>
            </EuiText>
            {device ? (
              <EuiFlexGroup gutterSize='xs' alignItems='baseline'>
                <EuiFlexItem grow={true}>
                  <EuiCode style={{ overflowWrap: 'anywhere', wordBreak: 'break-all' }}>
                    {device.qr_code?.url}
                  </EuiCode>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <CopyButton value={device.qr_code?.url || ''} />
                </EuiFlexItem>
              </EuiFlexGroup>
            ) : (
              <EuiSkeletonText lines={2} />
            )}
          </div>
        </Fragment>
      ),
    },
    {
      title: formatMessage(messages.enterCodeTitle),
      children: (
        <Fragment>
          <EuiText size='s'>
            <FormattedMessage {...messages.qrCodeLabel} />
          </EuiText>
          <EuiSpacer size='xs' />
          <EuiFieldText
            placeholder='E.g. 123456'
            icon='lock'
            autoComplete='off'
            aria-label={formatMessage(messages.qrCodeLabel)}
            style={{ maxWidth: '20rem' }}
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          {activateDeviceMutation.isError && (
            <Fragment>
              <EuiSpacer />
              <CuiAlert type='danger'>{activateDeviceMutation.error.errors[0]?.message}</CuiAlert>
            </Fragment>
          )}
        </Fragment>
      ),
    },
  ]

  const onSubmit = () => {
    if (!device) {
      return // sanity
    }

    activateDeviceMutation.mutate(
      { deviceId: device.device_id, passCode: code },
      {
        onSuccess: () => {
          closeModal()
        },
      },
    )
  }

  return (
    <EuiModal maxWidth='44rem' aria-labelledby={modalTitleId} onClose={closeModal}>
      <EuiModalHeader>
        <EuiModalHeaderTitle id={modalTitleId}>
          <FormattedMessage
            id='mfaEnforcement.google.title'
            defaultMessage='Set up authenticator app'
          />
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiSteps steps={steps} />
      </EuiModalBody>

      <EuiModalFooter>
        <EuiFlexGroup justifyContent='flexEnd'>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty onClick={closeModal}>
              <FormattedMessage {...genericMessages.cancel} />
            </EuiButtonEmpty>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton fill={true} isLoading={activateDeviceMutation.isLoading} onClick={onSubmit}>
              <FormattedMessage {...genericMessages.enableButton} />
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalFooter>
    </EuiModal>
  )
}

export default SetupAuthenticatorDevice
