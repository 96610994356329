/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiButton,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import { useGetDomainsQuery } from '@modules/security-idp-lib/hooks'

import AddDomainFlyout from '../AddDomainFlyout'
import DomainClaimTable from '../DomainClaimsTable'

import type { Props } from './types'

const DomainClaims: React.FC<Props> = (props: Props) => {
  const {
    organizationId,
    permissions: { hasViewDomainClaimPermission, hasUpdateDomainClaimPermission },
  } = props
  const { data, isLoading } = useGetDomainsQuery(organizationId)
  const [isDomainFlyoutOpen, setIsDomainFlyoutOpen] = useState(false)
  const domains = data?.domains

  if (!hasViewDomainClaimPermission) {
    return null
  }

  const hasNoDomains = !isLoading && domains?.length === 0
  return (
    <React.Fragment>
      <EuiPanel paddingSize='xl'>
        <EuiFlexGroup justifyContent='spaceBetween'>
          <EuiTitle size='m'>
            <h1>Domains</h1>
          </EuiTitle>
          {!hasNoDomains && (
            <EuiButton
              onClick={() => setIsDomainFlyoutOpen(true)}
              disabled={!hasUpdateDomainClaimPermission}
            >
              Add domain
            </EuiButton>
          )}
        </EuiFlexGroup>
        <EuiSpacer size='m' />
        {hasNoDomains ? (
          <NoDomains
            onOpenDomainFlyout={() => setIsDomainFlyoutOpen(true)}
            hasUpdateDomainClaimPermission={hasUpdateDomainClaimPermission}
          />
        ) : (
          <DomainClaimTable
            isLoading={isLoading}
            domains={domains}
            hasUpdateDomainClaimPermission={hasUpdateDomainClaimPermission}
          />
        )}
      </EuiPanel>
      {isDomainFlyoutOpen && <AddDomainFlyout closeFlyout={() => setIsDomainFlyoutOpen(false)} />}
    </React.Fragment>
  )
}

const NoDomains = ({
  onOpenDomainFlyout,
  hasUpdateDomainClaimPermission,
}: {
  onOpenDomainFlyout: () => void
  hasUpdateDomainClaimPermission: boolean
}) => (
  <EuiFlexGroup className='no-data-flex-group'>
    <EuiFlexItem className='no-data-left-panel'>
      <Description />
    </EuiFlexItem>
    <EuiText>
      <EuiFlexItem className='no-data-right-panel'>
        <EuiEmptyPrompt
          className='no-data-prompt'
          color='subdued'
          title={
            <h4>
              <FormattedMessage id='domains.add-a-domain' defaultMessage='Add a domain' />
            </h4>
          }
          body={
            <p>
              <FormattedMessage
                id='domains.add-a-domain-description'
                defaultMessage='You must verify at least one domain to enable identity provider authentication for the users of your organization and to manage their roles.'
              />
            </p>
          }
          actions={
            <React.Fragment>
              <EuiButton
                color='primary'
                fill={true}
                onClick={onOpenDomainFlyout}
                disabled={!hasUpdateDomainClaimPermission}
              >
                <FormattedMessage id='domains.add-domain' defaultMessage='Add domain' />
              </EuiButton>
              <EuiSpacer />
            </React.Fragment>
          }
        />
      </EuiFlexItem>
    </EuiText>
  </EuiFlexGroup>
)

const Description = () => (
  <EuiText color='subdued'>
    <FormattedMessage
      id='domains.description'
      defaultMessage='Add and verify the domains that you own to invite and manage users through your identity provider.'
    />
  </EuiText>
)

export default DomainClaims
